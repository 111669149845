import React from 'react';

interface ForeignLogoSvgProps {
  img?: string;
}

const ForeignLogoSvg: React.FC<ForeignLogoSvgProps> = ({ img }) => {
  const svgWidth = 24;
  const svgHeight = 24;

  return (
    <svg width={svgWidth} height={svgHeight} viewBox={`0 0 ${svgWidth} ${svgHeight}`} fill="none">
      {img && <image href={img} x="0" y="0" width={svgWidth} height={svgHeight} />}
    </svg>
  );
};

export default ForeignLogoSvg;
