import React, { FC } from "react";
import Avatar from "components/Avatar/Avatar";
import Link from "components/Link";
import { PostDataType } from "data/types/post";
import { getAuthorData } from "api/authors/Authors";

export interface ArticleCardMetaProps {
  className?: string;
  meta: Pick<PostDataType, "date" | "author" | "viewedCount">;
  hiddenAvatar?: boolean;
  avatarSize?: string;
}

const ArticleCardMeta: FC<ArticleCardMetaProps> = ({
  className = "leading-none text-xs",
  meta,
  hiddenAvatar = false,
  avatarSize = "h-7 w-7 text-sm",
}) => {
  let { date, author, viewedCount } = meta;
  if (!author) {
    author = getAuthorData()[0];
  }

  return (
    <div
      className={`nc-PostCardMeta inline-flex items-center flex-wrap text-neutral-800 dark:text-neutral-200 ${className}`}
    >
      {/* change 1 with 2 if you want to make link with author */}
      {/* <Link href={author.href} className="relative flex items-center space-x-2">
        {!hiddenAvatar && (
          <Avatar
            radius="rounded-full"
            sizeClass={avatarSize}
            imgUrl={author.avatar}
            userName={author.displayName}
          />
        )}
        <span className="block text-neutral-700 hover:text-black dark:text-neutral-300 dark:hover:text-white font-medium">
          {author.displayName}
        </span>
      </Link> */}

      {/* change 1 with 2 if you want to make link with author */}
      <span className="relative flex items-center space-x-2">
        {!hiddenAvatar && (
          <Avatar
            radius="rounded-full"
            sizeClass={avatarSize}
            imgUrl={author.avatar} // NOTE: avatar = imgUrl. you need to change it's name
            userName={author.displayName}
          />
        )}
        <span className="block text-neutral-700 dark:text-neutral-300 font-medium">
          {author.displayName}
        </span>
      </span>
      {/* change 1 with 2 if you want to make link with author */}
      
      <>
        <span className="text-neutral-500 dark:text-neutral-400 mx-[6px] font-medium">
          ·
        </span>
        <span className="text-neutral-500 dark:text-neutral-400 font-normal">
          {date}
        </span>
      </>

      {typeof viewedCount !== 'undefined' && viewedCount > 0 && viewedCount > 100 && (
        <>
          <span className="text-neutral-500 dark:text-neutral-400 mx-[6px] font-medium">
            ·
          </span>
          <span className="text-neutral-500 dark:text-neutral-400 font-normal">
            {viewedCount} views
          </span>
        </>
      )}
    </div>
  );
};

export default ArticleCardMeta;
