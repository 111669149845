import React, { useEffect, useState } from "react";
import Heading2 from "components/Heading/Heading2";
import Tab from "components/Tab/Tab";
import TabItem from "components/Tab/TabItem";
import CryptoTopLowTable from "components/Table/CryptoTopLowTable";
import { TopLowData, fetchTopLowData } from "api/cryptocurrency/toplow";
import { EventLog, fetchLatestEventLog } from "api/eventlogger/eventlog";

const TABS: { [label: string]: string } = {
  "30분": "30",
  "1시간": "60",
  "4시간": "240",
  "12시간": "12H",
  "24시간": "1D",
};

interface CryptoTrendViewProps {
  title: string;
  exchange: string;
  trade_type: string;
  event_log: string;
}

const convertBigIntToTimeString = (dateTimeBigInt: number): string => {
  const dateTimeStr = dateTimeBigInt.toString();
  if (dateTimeStr.length !== 14) {
    throw new Error(`Invalid datetime format ${dateTimeStr}`);
  }
  const hours = dateTimeStr.substring(8, 10);
  const minutes = dateTimeStr.substring(10, 12);
  return `${hours}:${minutes} 기준`;
};

const CryptoTrendView: React.FC<CryptoTrendViewProps> = ({
  title,
  exchange,
  trade_type = "spot",
  event_log,
}) => {
  const [tabActive, setTabActive] = useState(Object.keys(TABS)[0]);
  const [topData, setTopData] = useState<TopLowData[]>([]);
  const [lowData, setLowData] = useState<TopLowData[]>([]);
  const [latestEventLog, setLatestEventLog] = useState<EventLog | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  const fetchData = async (item: string) => {
    try {
      const data = await fetchTopLowData(
        trade_type,
        exchange,
        TABS[item],
        latestEventLog?.latestUpdatedAt || 0
      );
      const sortedData = [...data].sort(
        (a, b) => (b.priceDiffPercent || 0) - (a.priceDiffPercent || 0)
      );
      setTopData(sortedData.slice(0, 10));
      const bottomData = sortedData
        .slice(-10)
        .sort((a, b) => (a.priceDiffPercent || 0) - (b.priceDiffPercent || 0));
      setLowData(bottomData);
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const fetchedEventLog = await fetchLatestEventLog(event_log);
        setLatestEventLog(fetchedEventLog);
      } catch (error) {
        console.error("Error fetching latest event log: ", error);
      }
    };
    fetchInitialData();
  }, [exchange, tabActive]);

  useEffect(() => {
    // Perform any actions that depend on topData and lowData
    setIsLoading(false);
  }, [lowData]);

  useEffect(() => {
    if (latestEventLog) {
      fetchData(tabActive);
    }
  }, [latestEventLog]);

  const handleClickTab = async (item: string) => {
    if (item === tabActive) {
      return;
    }
    setTabActive(item);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="flex flex-col sm:flex-row justify-between items-stretch gap-4">
      <div className="flex-1 flex-shrink min-w-0 p-5 mx-auto bg-white rounded-xl sm:rounded-3xl lg:rounded-[40px] shadow-lg sm:p-10 lg:p-16 dark:bg-neutral-900 overflow-x-auto">
        <header className="text-center max-w-2xl mx-auto - mb-14 sm:mb-16 lg:mb-24">
          <Heading2 emoji="">{title}</Heading2>
          <span className="block text-sm mt-2 text-neutral-700 sm:text-base dark:text-neutral-200"></span>
        </header>
        <div className="mb-4">
          <Tab
            containerClassName="w-full overflow-x-auto hiddenScrollbar"
            className="sm:space-x-2"
          >
            {Object.entries(TABS).map(([key, value], index: number) => (
              <TabItem
                isActive={key === tabActive}
                key={index}
                onClick={() => handleClickTab(key)}
              >
                {key}
              </TabItem>
            ))}
          </Tab>
        </div>
        <div className="flex flex-col">
          <div className="text-right mb-2">
            <span className="text-sm font-medium font-semibold px-4 py-1">
              {latestEventLog &&
                convertBigIntToTimeString(latestEventLog.latestUpdatedAt)}
            </span>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="crypto-table-container">
              <CryptoTopLowTable data={topData}></CryptoTopLowTable>
            </div>
            <div className="crypto-table-container">
              <CryptoTopLowTable data={lowData}></CryptoTopLowTable>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 gap-6 md:gap-8 mt-3"></div>
      </div>
    </div>
  );
};

export default CryptoTrendView;
