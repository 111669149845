import { NavItemType } from "components/Navigation/NavigationItem";
import _ from "lodash";

const randomId = _.uniqueId;

const OTHER_PAGE_CHILD: NavItemType[] = [
  {
    id: randomId(),
    href: "/dashboard/notice",
    name: "공지사항",
  },
];

export const NAVIGATION_ITEM: NavItemType[] = [
  {
    id: randomId(),
    href: "/",
    name: "홈",
  },
  {
    id: randomId(),
    href: "/blog",
    name: "블로그",
    type: "dropdown",
    children: [
      {
        id: randomId(),
        href: "/blog/cryptocurrency",
        name: "가상화폐",
      },
      {
        id: randomId(),
        href: "/blog/develop",
        name: "개발/IT",
      },
    ],
  },
  {
    id: randomId(),
    href: "/airdrop",
    name: "에어드랍",
  },
  {
    id: randomId(),
    href: "/explore/platform-pick",
    name: "탐색",
    type: "dropdown",
    children: [
      {
        id: randomId(),
        href: "/dashboard/notice",
        name: "공지사항",
      },
      {
        id: randomId(),
        href: "/explore/platform-pick",
        name: "BQ의 플랫폼 픽",
      },
      {
        id: randomId(),
        href: "/explore/news",
        name: "최신 가상화폐 뉴스",
      },
      {
        id: randomId(),
        href: "/explore/education",
        name: "교육 컨텐츠",
        type: "dropdown",
        children: [
          {
            id: randomId(),
            href: "/explore/education/blockchain-simulator",
            name: "블록체인 시뮬레이션",
          },
          {
            id: randomId(),
            href: "/explore/education/hash-generator",
            name: "Live 해쉬 툴",
          },
        ],
      },
      // {
      //   id: randomId(),
      //   href: "/explore/crypto-trend",
      //   name: "급등락 코인",
      // },
      
    ],
  },
  {
    id: randomId(),
    href: "/implementing",
    name: "트레이딩",
    type: "dropdown",
    children: [
      {
        id: randomId(),
        href: "/implementing",
        name: "가상화폐",
      },
      {
        id: randomId(),
        href: "/implementing",
        name: "개발/IT",
      },
    ],
  },
  // {
  //   id: randomId(),
  //   href: "/dashboard/notice",
  //   name: "탐색",
  //   type: "dropdown",
  //   children: OTHER_PAGE_CHILD,
  // },
];
