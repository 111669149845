import React, { FC } from "react";
import CategoryBadgeList from "components/CategoryBadgeList/CategoryBadgeList";
import PostTypeFeaturedIcon from "components/PostTypeFeaturedIcon/PostTypeFeaturedIcon";
import Link from "components/Link";
import Image from "components/Image/Image";
import PostCardLikeAndCommentButton from "components/Button/PostCardLikeAndComment/PostCardLikeAndCommentButton";
import { PostDataType, TaxonomyType } from "data/types/post";
import ArticleCardMeta from "./ArticleCardMeta";
import PostCardSaveActionButton from "components/Button/PostCardSaveActionButton";

export interface ArticleCardProps {
  className?: string;
  post: PostDataType;
}

const ArticleCard: FC<ArticleCardProps> = ({ className = "h-full", post }) => {
  const {
    id,
    title,
    href,
    readingTime,
    featuredImage,
    desc,
    categories,
    postType,
    viewedCount = 0,
  } = post;
  
  const blogPostUrl = `/blog/${id}`;
  return (
    <div
      className={`nc-Card3 relative flex flex-row items-center group ${className}`}
    >
      <div className="flex flex-col flex-grow">
        <div className="space-y-3.5">
          <CategoryBadgeList categories={categories as TaxonomyType[]} />
          <Link href={blogPostUrl} className="block">
            <h2
              className={`nc-card-title block font-medium sm:font-semibold text-neutral-900 dark:text-neutral-100 text-sm sm:text-base xl:text-lg`}
            >
              <span className="line-clamp-2" title={title}>
                {title}
              </span>
            </h2>
            <div className="hidden sm:block sm:mt-2">
              <span className="text-neutral-500 dark:text-neutral-400 text-sm line-clamp-2">
                {desc}
              </span>
            </div>
          </Link>

          <ArticleCardMeta meta={{ ...post }} />
        </div>
        <div className="mt-5 flex items-center flex-wrap justify-between">
          {/* <PostCardLikeAndCommentButton /> */}
          {/* <PostCardSaveActionButton readingTime={readingTime} /> */}
        </div>
      </div>

      <div
        className={`block flex-shrink-0 w-24 sm:w-36 md:w-44 xl:w-56 ml-3 sm:ml-6 rounded-3xl overflow-hidden z-0 mb-5 sm:mb-0`}
      >
        <Link
          href={blogPostUrl}
          className="block w-full h-0 aspect-h-1 aspect-w-1 relative"
        >
          <Image
            containerClassName="absolute inset-0"
            src={featuredImage}
            fill
            alt={title}
          />
          <span>
            <PostTypeFeaturedIcon
              className="absolute left-2 bottom-2"
              postType={postType}
              wrapSize="w-8 h-8"
              iconSize="w-4 h-4"
            />
          </span>
        </Link>
      </div>
    </div>
  );
};

export default ArticleCard;
