import React, { FC } from "react";

export interface NoticeTitleProps {
  title: string;
  className?: string;
  mainClass?: string;
}

const NoticeTitle: FC<NoticeTitleProps> = ({
  mainClass = "text-neutral-600 font-semibold text-2xl md:text-2xl md:!leading-[120%] lg:text-3xl dark:text-neutral-100",
  className = "",
  title,
}) => {
  return (
    <h2 className={className + " " + mainClass + " max-w-4xl "} title={title}>
      {title}
    </h2>
  );
};

export default NoticeTitle;
