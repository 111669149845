// MarkdownEditorPage.tsx
import { getAuthorData } from "api/authors/Authors";
import { PostAuthorType } from "data/types/author";

import React, { ChangeEvent, useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import PreviewModal from "./PreviewModal";
import DatePickerComponent from "components/DatePicker/DatePicker";
import { DEMO_CATEGORIES } from "api/tags/Category";
import getPostById, { createPost, deletePostById } from "api/posts/posts";
import { randomUUID } from "crypto";
import { uniqueId } from "lodash";
import { v4 as uuidv4 } from "uuid";
import { useNavigate, useParams } from "react-router-dom";
import {
  convertFormattedStringToDate,
  convertToISO,
} from "utils/date/dateutil";

const MarkdownEditorWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Textarea = styled.textarea`
  width: 100%;
  height: 600px; // Updated height
  font-family: "Courier New", Courier, monospace;
  font-size: 16px;
  padding: 10px;
`;

const Button = styled.button`
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 16px;
`;

const Input = styled.input`
  margin-top: 10px;
  padding: 10px;
  font-size: 16px;
  width: 100%;
`;

const ImagePreview = styled.img`
  max-width: 100%;
  height: auto;
  margin-top: 10px;
`;

const Heading = styled.h3`
  margin-top: 20px;
  font-size: 20px;
  color: #333;
`;

const Select = styled.select`
  margin-top: 10px;
  padding: 10px;
  font-size: 16px;
  width: 100%;
  height: ${(props) => (props.multiple ? "auto" : "50px")};
  min-height: 50px;
  overflow-y: ${(props) => (props.multiple ? "auto" : "hidden")};
`;
const CategoryChip = styled.div`
  display: inline-block;
  padding: 8px 12px;
  margin: 4px;
  font-size: 14px;
  color: white;
  background-color: #007bff;
  border: none;
  border-radius: 15px;
  cursor: pointer;
  user-select: none;

  &.selected {
    background-color: #0056b3;
  }

  &:hover {
    opacity: 0.7;
  }
`;

export interface MarkdownEditorPageProps {
  postId?: string;
}

const MarkdownEditorPage: React.FC<MarkdownEditorPageProps> = ({ postId }) => {
  const [markdownText, setMarkdownText] = useState<string>("");
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [title, setTitle] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [keywords, setKeywords] = useState<string>("");
  const [image, setImage] = useState<File | null>(null);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());
  const [selectedAuthorId, setSelectedAuthorId] = useState<number | string>("");
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [selectedPostType, setSelectedPostType] = useState<string>("standard");
  const [originalContent, setOriginalContent] = useState<string>("");
  const navigate = useNavigate();
  const authors = getAuthorData();

  useEffect(() => {
    const fetchPostData = async () => {
      if (postId) {
        try {
          const postData = await getPostById(postId);
          // Prefill form fields with postData
          setTitle(postData.title);
          setMarkdownText(postData.content || "");
          setSelectedAuthorId(postData.author.id);
          setSelectedCategories((postData.categories as string[]) || [""]);
          setSelectedDate(convertFormattedStringToDate(postData.date));
          setPreviewUrl(postData.featuredImage);
          setOriginalContent(postData.originalContent || ""); // Set the originalContent state
          setSelectedPostType(postData.postType || "standard");
          // Any other fields that need to be pre-filled
        } catch (error) {
          console.error(`Error fetching post data ${error}`);
          // Handle error appropriately
        }
      }
    };

    fetchPostData();
  }, [postId]);

  const toggleCategorySelection = (categoryName: string) => {
    if (selectedCategories.includes(categoryName)) {
      setSelectedCategories(
        selectedCategories.filter((name) => name !== categoryName)
      );
    } else {
      setSelectedCategories([...selectedCategories, categoryName]);
    }
  };

  const handleAuthorChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setSelectedAuthorId(e.target.value);
  };

  const handleTitleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value);
  };

  const handleDescriptionChange = (e: ChangeEvent<HTMLInputElement>) => {
    setDescription(e.target.value);
  };

  const handleKeywordsChange = (e: ChangeEvent<HTMLInputElement>) => {
    setKeywords(e.target.value);
  };

  const handleImageChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      setImage(file);
    }
  };

  const handleImageUpload = async () => {
    if (image) {
      const formData = new FormData();
      formData.append("file", image);

      try {
        const response = await axios.post(
          "https://metadata-api.klaytnapi.com/v1/metadata/asset",
          formData,
          {
            headers: {
              "x-chain-id": "1001",
              Authorization:
                "Basic S0FTS1laQ1BLR0EwU1VQMlpNOEZXOFk4OjdlSmluNXpGcTZaTXU5cG8tWDZJM2RteHRpc210bjBuNG5nVHBoNHg=",
              "Content-Type": "multipart/form-data",
            },
          }
        );
        setPreviewUrl(response.data.uri);
        alert("Image uploaded successfully!");
      } catch (error) {
        console.error("Error uploading the image", error);
        alert("Failed to upload image");
      }
    }
  };

  const handleDateChange = (date: Date) => {
    setSelectedDate(date);
  };

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setMarkdownText(e.target.value);
    setOriginalContent(e.target.value); // Update originalContent when the markdown text changes
  };

  const handlePreview = () => {
    setIsModalOpen(true);
  };

  const handleDelete = async () => {
    // Confirm before deleting
    const isConfirmed = window.confirm(
      `Are you sure you want to delete the blog titled "${title}"?`
    );

    if (isConfirmed && postId) {
      try {
        await deletePostById(postId);
        // Handle successful deletion, perhaps redirecting to another page or showing a success message
        alert("Post deleted successfully.");
        navigate("/blog");
      } catch (error) {
        console.error("Error deleting the blog post", error);
        // Handle error case, such as displaying an error message
        alert("Failed to delete the post.");
      }
    }
  };

  const handleSave = async () => {
    // Validation
    if (!title.trim()) {
      alert("Title is required.");
      return;
    }

    if (selectedAuthorId === undefined || selectedAuthorId === "") {
      alert(`You must select an author ${selectedAuthorId}`);
      return;
    }

    if (selectedPostType == "standard" && selectedCategories.length === 0) {
      alert("You must select at least one category.");
      return;
    }

    if (!selectedDate) {
      alert("You must select a publish date.");
      return;
    }

    if (!markdownText.trim()) {
      alert("Content is required.");
      return;
    }

    const originalMarkdownContent = originalContent;

    // Assuming 'getAuthorData' fetches an array of authors and 'selectedAuthorId' is one of their IDs
    const authorData = authors[selectedAuthorId as number];

    // Assuming 'likeData' and 'bookmarkData' should be provided with some default or user-inputted values
    const likeData = { count: 0, isLiked: false }; // Replace with actual data if needed
    const bookmarkData = { count: 0, isBookmarked: false }; // Replace with actual data if needed

    // The markdown content encoded
    const encodedContent = new TextDecoder().decode(
      encode_markdown(markdownText)
    );

    const id = postId || uuidv4();
    // Create the payload matching 'CreatePostRequestDto' structure
    const payload = {
      id: id,
      author: authorData?.displayName || "BQ", // or another property that represents the author name
      date: selectedDate?.toISOString() || new Date().toISOString(),
      categories: selectedCategories, // This now contains the selected categories
      href: `/blog/${id}`, // this will be set by the server
      title: title,
      featuredImage: previewUrl || "", // assuming this is the URL of the uploaded image
      desc: "",
      content: encodedContent,
      originalContent: originalMarkdownContent, // Add originalContent to the payload
      like: likeData,
      bookmark: bookmarkData,
      commentCount: 0,
      viewedCount: 0,
      readingTime: 0,
      postType: selectedPostType,
      videoUrl: null,
      ogs: {
        title: title,
        description: description,
        keywords: keywords,
        image: previewUrl || "",
        url: `/blog/${id}`,
      },
    };

    try {
      // Making a POST request to the server with the payload
      // const response = await axios.post('/blog', payload);
      // console.log(response.data);
      const response = createPost(payload);
      alert("Saved successfully!");
      navigate(`/article-preview/${payload.id}`);
    } catch (error) {
      console.error("Error saving the blog post", error);
      alert("Failed to save");
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const encode_markdown = (markdownContent: string) => {
    const imgRegex = /!(https?:\/\/[^\s]+)/g;
    let updatedContent = markdownContent.replace(imgRegex, "![Untitled]($1)");

    const codeBlockRegex = /```(?:(\w+)(?:\s*\n))?([^`]+?)\s*```/gs;
    // Updating code blocks
    updatedContent = updatedContent.replace(codeBlockRegex, (match, p1, p2) => {
      if (p1) {
        // Code block already has a specified language
        return match;
      } else {
        // Code block does not have a specified language, adding "Text"
        return "```Text\n" + p2.trim() + "\n```";
      }
    });

    return new TextEncoder().encode(updatedContent);
  };

  return (
    <div className={`nc-PageSingle pt-8 lg:pt-16`}>
      <MarkdownEditorWrapper>
        <Heading>Post Type</Heading>
        <Select
          value={selectedPostType}
          onChange={(e) => setSelectedPostType(e.target.value)}
        >
          <option value="standard">Standard</option>
          {/* <option value="cryptocurrency">Cryptocurrency</option> */}
          <option value="notice">Notice</option>
          <option value="airdrop">Airdrop</option>
          <option value="develop">Develop</option>
        </Select>
        <Heading>Title</Heading>
        <Input
          type="text"
          value={title}
          onChange={handleTitleChange}
          placeholder="Enter title here..."
        />
        <Heading>Description</Heading>
        <Input
          type="text"
          value={description}
          onChange={handleDescriptionChange}
          placeholder="Enter description for og here... (150-160 words)"
        />
        <Heading>Keywords</Heading>
        <Input
          type="text"
          value={keywords}
          onChange={handleKeywordsChange}
          placeholder="Enter description for og here...  e.g. Layer2, Ethereum, STO"
        />
        <Heading>Select Author</Heading>
        <Select value={selectedAuthorId} onChange={handleAuthorChange}>
          <option value="">Select an author</option>
          {authors.map((author) => (
            <option key={author.id} value={author.id}>
              {author.displayName}
            </option>
          ))}
        </Select>
        <Heading>Categories</Heading>
        <div>
          {DEMO_CATEGORIES.map((category) => (
            <CategoryChip
              key={category.id}
              onClick={() => toggleCategorySelection(category.name)}
              className={
                selectedCategories.includes(category.name) ? "selected" : ""
              }
            >
              {category.name}
            </CategoryChip>
          ))}
        </div>
        <Heading>Publish Date</Heading>
        <DatePickerComponent
          selectedDate={selectedDate}
          onChange={handleDateChange}
        />
        <Heading>Upload Thumbnail Image</Heading>
        <Input type="file" onChange={handleImageChange} />
        {previewUrl && <ImagePreview src={previewUrl} alt="Preview" />}
        <Button onClick={handleImageUpload}>Upload Image</Button>
        <Heading>Contents</Heading>
        <Textarea
          value={originalContent}
          onChange={handleChange}
          placeholder="Write your markdown here..."
        />
        <Button onClick={handlePreview}>Preview</Button>
        <Button onClick={handleSave}>Save</Button>
        <PreviewModal
          isModalOpen={isModalOpen}
          closeModal={closeModal}
          markdownText={new TextDecoder().decode(
            encode_markdown(originalContent)
          )}
        />
        {postId && <Button onClick={handleDelete}>Delete</Button>}
      </MarkdownEditorWrapper>
    </div>
  );
};

export default MarkdownEditorPage;
