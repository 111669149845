// original name: Layout
import React, { ReactNode } from "react";
import ArticleContent from "./ArticleContent";
import { PostDataType } from "data/types/post";

interface ArticleLayoutProps {
  children: ReactNode;
  postData: PostDataType;
}

const ArticleLayout = ({ children, postData }: ArticleLayoutProps) => {
  return (
    <div>
      {children}

      {/* SINGLE MAIN CONTENT */}
      <div className="container mt-10">
        <ArticleContent postData={postData} />
      </div>

      {/* RELATED POSTS */}
      {/* <SingleRelatedPosts /> */}
    </div>
  );
};

export default ArticleLayout;
