import React, { FC, ReactNode } from "react";

export interface TabItemProps {
  className?: string;
  radius?: string;
  onClick?: () => void;
  isActive?: boolean;
  renderX?: ReactNode;
  children?: ReactNode;
}

const TabItem: FC<TabItemProps> = ({
  className="px-2 py-1 text-xs md:px-5 md:py-2.5 sm:text-sm md:px-6 md:py-3 capitalize",
  radius = "rounded-full",
  children,
  onClick = () => {},
  isActive = false,
  renderX,
}) => {
  return (
    <li className="nc-NavItem relative flex-shrink-0">
      {renderX && renderX}
      <button
        className={`flex items-center justify-center font-medium ${className} ${radius} ${
          isActive
            ? "bg-neutral-900 text-neutral-50 dark:bg-neutral-100 dark:text-black"
            : "text-neutral-500 dark:text-neutral-400 dark:hover:text-neutral-100 hover:text-neutral-900 hover:bg-neutral-100 dark:hover:bg-neutral-800"
        } `}
        onClick={onClick}
      >
        {children}
      </button>
    </li>
  );
};

export default TabItem;
