import React from "react";
import logoImg from "assets/images/logos/bq-logo.png";
import LogoSvg from "./LogoSvg";
import Link from "components/Link";

export interface LogoProps {
  img?: string;
  href?: string;
}

const Logo: React.FC<LogoProps> = ({
  img = logoImg,
  href = "/",
}) => {
  return (
    <Link
      href={href}
      className="ttnc-logo inline-block text-primary-6000 flex-shrink-0"
    >
    <LogoSvg img={img} />
    </Link>
  );
};

export default Logo;
