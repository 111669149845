import __posts from "./video_posts.json";
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { PostDataType } from "data/types/post";
import { getAuthorData
 } from "api/authors/Authors";
import { formatDate } from "utils/date/dateutil";
import { getTaxonomiesByNames } from "api/tags/Category";


const authors = getAuthorData();
const API_BASE_URL = process.env.REACT_APP_API_HOST;
// const API_BASE_URL = "https://blog.bqyoutube.com";

export const getYoutubeVideos = async (): Promise<PostDataType[]> => {
  try {
    const response = await axios.get<PostDataType[]>(`${API_BASE_URL}/posts/recent?size=6&postType=video`);
    const result = response.data.map((post: any) => {
      return {
        ...post,
        date: formatDate(post.date),
        href: post.href || '',
        categories: getTaxonomiesByNames(post.categories),
        author: authors.filter((author) => author.displayName === post.author)[0],
      }
    }); 
    // console.log("recent result", JSON.stringify(result));
    return result;
  } catch (error) {
    // Assuming the error is an instance of AxiosError which has the `response` property.
    // You might want to adjust this if your error objects are different.
    if (axios.isAxiosError(error)) {
      // Handle error response (e.g., log it or transform it)
      throw new Error(error.response?.data || "An error occurred while fetching the posts");
    }
    // If it's not an AxiosError, it could be something else, like a network issue.
    throw new Error("Network error or other issue while fetching the posts");
  }
};



