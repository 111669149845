import React, { ReactNode, createContext, useContext, useState, useEffect } from "react";

export type Language = "English" | "Korean"; // Add new languages as needed

interface LanguageContextType {
  selectedLanguage: Language;
  changeLanguage: (newLanguage: Language) => void;
}

const STORAGE_KEY = "selectedLanguage";

const LanguageContext = createContext<LanguageContextType>({} as LanguageContextType);

export const LanguageProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [selectedLanguage, setSelectedLanguage] = useState<Language>(() => {
    // Retrieve the selected language from localStorage on initialization
    const storedLanguage = localStorage.getItem(STORAGE_KEY);
    return (storedLanguage as Language) || "English"; // Default to 'English' if not found in storage
  });

  const changeLanguage = (newLanguage: Language) => {
    setSelectedLanguage(newLanguage);
  };

  // Use useEffect to persist the selected language in localStorage
  useEffect(() => {
    localStorage.setItem(STORAGE_KEY, selectedLanguage);
  }, [selectedLanguage]);

  return (
    <LanguageContext.Provider value={{ selectedLanguage, changeLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => {
  const context = useContext(LanguageContext);
  if (!context) {
    throw new Error("useLanguage must be used within a LanguageProvider");
  }
  return context;
};
