import React, { FC } from "react";

// Importing the PNGs
import telegramLogo from "assets/images/logos/telegram-logo.png";
import youtubeLogo from "assets/images/logos/youtube-logo.webp";
import twitterLogo from "assets/images/logos/x-logo.webp";import { BQ_TELEGRAM_LINK, BQ_TWITTER_LINK, BQ_YOUTUBE_LINK } from "data/links/links";
;  

export interface SocialType {
    id: string;
    name: string;
    icon: string;  // Changing type to string to hold the image path
    href: string;
}



const socials: SocialType[] = [
  {
    id: "Youtube",
    name: "Youtube",
    icon: youtubeLogo,  // Using imported PNG
    href: BQ_YOUTUBE_LINK,
  },
  {
    id: "Telegram",
    name: "Telegram",
    icon: telegramLogo,  // Using imported PNG
    href: BQ_TELEGRAM_LINK,
  },
  {
    id: "Twitter",
    name: "Twitter",
    icon: twitterLogo,   // Using imported PNG
    href: BQ_TWITTER_LINK,
  },
];

export const SOCIALS_DATA = socials;
