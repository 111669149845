import React, { useEffect, useState } from "react";
import Image from "components/Image/Image";
import ArticleLayout from "components/Article/ArticleLayout";
import ArticleHeader from "components/Article/ArticleHeader";
import getPostById, { DEMO_POSTS } from "api/posts/posts";
import { Navigate, useParams } from "react-router-dom";
import { PostDataType } from "data/types/post";
import Loading from "components/Button/Loading";
import SEOMetaTag from "components/SEOMetaTag/SEOMetaTag";

// TODO: get page content and pass it to ArticleLayout, ArticleHeader
const ArticlePage = () => {
  const { postId } = useParams();
  const [post, setPost] = useState<PostDataType | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    if (postId) {
      getPostById(postId)
        .then((data) => {
          setPost(data);
          setLoading(false);
        })
        .catch((err) => {
          console.error("Error fetching post:", err);
          setError("Error fetching post");
          setLoading(false);
        });
    } else {
      setError("Post ID not provided");
      setLoading(false);
    }
  }, [postId]);

  if (loading) {
    return <Loading />;
  }

  if (error || !post) {
    return <Navigate to="/404" />;
  }
  const defaultOgs = {
    title: "",
    description: "",
    keywords: "",
    image: "",
    url: "https://bqyoutube.com",
  };
  return (
    <ArticleLayout postData={post}>
      <div className={`nc-PageSingle pt-8 lg:pt-16`}>
        <SEOMetaTag ogs={post.ogs || defaultOgs}></SEOMetaTag>
        <header className="container rounded-xl">
          <div className="max-w-screen-md mx-auto">
            <ArticleHeader postData={post} />
          </div>
        </header>

        {/* FEATURED IMAGE */}
        <Image
          alt="single"
          containerClassName="container my-10 sm:my-12"
          className="w-full rounded-xl"
          src={post.featuredImage}
          width={1260}
          height={750}
          sizes="(max-width: 1024px) 100vw, 1280px"
        />
      </div>
    </ArticleLayout>
  );
};

export default ArticlePage;
