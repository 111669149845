// original name : PostFeaturedMedia
import React, { FC } from "react";
import PostTypeFeaturedIcon from "components/PostTypeFeaturedIcon/PostTypeFeaturedIcon";
import Link from "components/Link";
import Image from "components/Image/Image";
import { PostDataType } from "data/types/post";
import MediaVideo from "components/Video/MediaVideo";

export interface PostFeatureVideoProps {
  className?: string;
  post: PostDataType;
  isHover?: boolean;
}

const PostFeaturedVideo: FC<PostFeatureVideoProps> = ({
  className = "w-full h-full",
  post,
  isHover = false,
}) => {
  const { featuredImage, postType, videoUrl, galleryImgs, audioUrl, href } =
    post;

  const isPostMedia = () => postType === "video";


  const renderContent = () => {
    // VIDEO
    if (postType === "video" && !!videoUrl && isHover) {
      return <MediaVideo isHover videoUrl={videoUrl} />;
    }
    // ICON
    return isPostMedia() ? (
      <span className="absolute inset-0 flex items-center justify-center ">
        <PostTypeFeaturedIcon
          className="hover:scale-105 transform cursor-pointer transition-transform"
          postType={postType}
        />
      </span>
    ) : null;
  };

  return (
    <div className={`PostFeaturedMedia relative ${className}`}>
      <Image
        alt="featured"
        fill
        className="object-contain w-full h-full"
        src={featuredImage}
        sizes="(max-width: 600px) 480px, 800px"
      />
      
      {renderContent()}
      <Link
        href={href}
        className={`block absolute inset-0 ${
          !postType || postType === "standard"
            ? "bg-black/20 transition-opacity opacity-0 group-hover:opacity-100"
            : ""
        }`}
      />
    </div>
  );
};

export default PostFeaturedVideo;
