// api.js
import axios from 'axios';

const apiHost = process.env.REACT_APP_API_HOST;
// const apiHost = "http://localhost:8080"

axios.defaults.withCredentials = true;
const bq_client = axios.create({
  withCredentials: true,
  baseURL: apiHost,
  timeout: 5000, // Set your desired timeout,
});

export default bq_client;
