import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_API_HOST;

// Define the TypeScript type for EventLog
export interface EventLog {
  id: number;
  type: string;
  latestUpdatedAt: number;
}

export const fetchLatestEventLog = async (
  type: string
): Promise<EventLog> => {
  try {
    const response = await axios.get<EventLog>(
      `${API_BASE_URL}/api/event-logs/latest`,
      { params: { type } }
    );

    // Assuming the API returns an EventLog object
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error(
        error.response?.data.message ||
          "An error occurred while fetching the latest event log"
      );
    }
    throw new Error(
      "Network error or other issue while fetching the latest event log"
    );
  }
};
