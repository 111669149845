import { TopLowData } from "api/cryptocurrency/toplow";
import React from "react";

interface CryptoTopLowTableProps {
  data: TopLowData[];
}

const CryptoTopLowTable: React.FC<CryptoTopLowTableProps> = ({ data }) => {
  return (
    <div className="shadow dark:border dark:border-neutral-800 overflow-hidden sm:rounded-lg">
      <table className="min-w-full divide-y divide-neutral-200 dark:divide-neutral-800">
        <thead className="bg-neutral-50 dark:bg-neutral-800">
          <tr className="text-center text-xs sm:text-sm font-medium text-neutral-500 dark:text-neutral-300 uppercase tracking-wider">
            <th scope="col" className="px-6 py-3" style={{ width: "60%" }}>
              이름
            </th>
            <th scope="col" className="px-6 py-3" style={{ width: "40%" }}>
              가격 변동 (%)
            </th>
          </tr>
        </thead>
        <tbody className="text-center bg-white dark:bg-neutral-900 divide-y divide-neutral-200 dark:divide-neutral-800">
          {data.map((item, index) => (
            <tr key={index}>
              <td
                className="px-6 py-4 whitespace-nowrap text-xs sm:text-sm text-neutral-500 dark:text-neutral-400"
                style={{ width: "60%" }}
              >
                {item.symbol}
              </td>
              <td
                className={`px-6 py-4 whitespace-nowrap text-xs sm:text-sm ${
                  item.priceDiffPercent!! < 0 ? "text-red-500" : "text-blue-500"
                }`}
                style={{ width: "40%" }}
              >
                {item.priceDiffPercent!!.toFixed(2)}%
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CryptoTopLowTable;
