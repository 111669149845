import React, { useEffect, useState } from "react";
import NewsPosts from "components/News/NewsPosts";
import { fetchPostsByAuthor } from "api/posts/posts";
import { PostDataType } from "data/types/post";
import { formatRelativeTime } from "utils/date/dateutil";
import Heading2 from "components/Heading/Heading2";
import Avatar from "components/Avatar/Avatar";

const NewsPage: React.FC = () => {
  const [theblockPosts, setTheblockPosts] = useState<PostDataType[]>([]);
  const [coindeskPosts, setCoindeskPosts] = useState<PostDataType[]>([]);
  const [coindeskKoreaPosts, setCoindeskKoreaPosts] = useState<PostDataType[]>(
    []
  );
  const [coinnessPosts, setcoinnessPosts] = useState<PostDataType[]>([]);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchAndFormatPosts = async (
      author: string,
      setState: React.Dispatch<React.SetStateAction<PostDataType[]>>
    ) => {
      try {
        const fetchedPosts = await fetchPostsByAuthor(author, 10, "news");
        const formattedPosts = fetchedPosts.map((post) => ({
          ...post,
          date: formatRelativeTime(post.date),
        }));
        // console.log("formattedPosts", formattedPosts)
        setState(formattedPosts);
      } catch (error) {
        console.error(`Error fetching posts for ${author}:`, error);
      }
    };

    const fetchAllPosts = async () => {
      await Promise.all([
        fetchAndFormatPosts("the-block", setTheblockPosts),
        fetchAndFormatPosts("coindesk", setCoindeskPosts),
        fetchAndFormatPosts("coindesk-korea", setCoindeskKoreaPosts),
        fetchAndFormatPosts("coinness", setcoinnessPosts),
      ]);
      setIsLoading(false);
    };

    fetchAllPosts();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container relative pt-6 sm:pt-10 pb-16 lg:pt-20 lg:pb-28">
      <div className="p-5 mx-auto bg-white rounded-xl sm:rounded-3xl lg:rounded-[40px] shadow-lg sm:p-10 lg:p-16 dark:bg-neutral-900">
        <header className="text-center max-w-2xl mx-auto - mb-14 sm:mb-16 lg:mb-24">
          <Heading2 emoji="">최신 가상화폐 뉴스</Heading2>
          <span className="block text-sm mt-2 text-neutral-700 sm:text-base dark:text-neutral-200"></span>
        </header>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 gap-6 md:gap-8 mt-3">
          <NewsPosts
            title="더블록 뉴스"
            posts={theblockPosts}
            avatar={
              <Avatar
                radius="rounded-full"
                sizeClass={"h-7 w-7 text-sm"}
                imgUrl={
                  "https://asset.brandfetch.io/idKIPMiOhQ/idJ6xJmEmY.jpeg"
                }
                userName={""}
              />
            }
            href={"https://www.theblock.co"}
          ></NewsPosts>
          <NewsPosts
            title="코인데스크 뉴스"
            posts={coindeskPosts}
            avatar={
              <Avatar
                radius="rounded-full"
                sizeClass={"h-7 w-7 text-sm"}
                imgUrl={
                  "https://metadata-store.klaytnapi.com/4feea07c-8a03-da99-bab4-a11b0816c04a/dad23f40-7462-bc5d-87e1-10ec08cce486.jpeg"
                }
                userName={""}
              />
            }
            href={"https://www.coindesk.com"}
          ></NewsPosts>
          <NewsPosts
            title="코인데스크 코리아 뉴스"
            posts={coindeskKoreaPosts}
            avatar={
              <Avatar
                radius="rounded-full"
                sizeClass={"h-7 w-7 text-sm"}
                imgUrl={
                  "https://metadata-store.klaytnapi.com/4feea07c-8a03-da99-bab4-a11b0816c04a/dad23f40-7462-bc5d-87e1-10ec08cce486.jpeg"
                }
                userName={""}
              />
            }
            href={"https://www.coindeskkorea.com"}
          ></NewsPosts>{" "}
          <NewsPosts
            title="코인니스 뉴스"
            posts={coinnessPosts}
            avatar={
              <Avatar
                radius="rounded-full"
                sizeClass={"h-7 w-7 text-sm"}
                imgUrl={
                  "https://metadata-store.klaytnapi.com/4feea07c-8a03-da99-bab4-a11b0816c04a/f4b204db-e0e4-8f28-5d52-fb43a37e18da.png"
                }
                userName={""}
              />
            }
            href={"https://www.coinness.com"}
          ></NewsPosts>
        </div>
      </div>
    </div>
  );
};

export default NewsPage;
