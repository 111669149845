import React from 'react';

interface LogoSvgProps {
  img?: string;
}

const LogoSvg: React.FC<LogoSvgProps> = ({ img }) => {
  return (
    <svg
      width="59"
      height="41"
      viewBox="0 0 59 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="mx-auto rounded-full"
    >
      {img && <image href={img} x="0" y="0" height="41" width="59"/>}
    </svg>
  );
};

export default LogoSvg;
