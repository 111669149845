import ButtonPrimary from "components/Button/ButtonPrimary";
import React from "react";
import implementingImage from '../../assets/page-implementing.png';  // Adjust the path as necessary

const PageImplementing: React.FC = () => (
  <div className="nc-Page404">
    <div className="container relative py-16 lg:py-20">
      {/* HEADER */}
      <header className="text-center max-w-2xl mx-auto space-y-7">
        <img src={implementingImage} alt="Page Not Found" className="mx-auto w-64 h-64 rounded-full"/>
        <h1 className="text-8xl md:text-xl font-semibold tracking-widest">
          Thank You For Your Patience (구현중)
        </h1>
        <span className="block text-sm text-neutral-800 sm:text-base dark:text-neutral-200 tracking-wider font-medium">
          {`THE PAGE YOU ARE LOOKING FOR WILL BE IMPLEMENTED SOON`}
        </span>
        <ButtonPrimary href="/" className="mt-4">
          Return Home Page
        </ButtonPrimary>
      </header>
    </div>
  </div>
);

export default PageImplementing;
