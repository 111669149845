import React, { FC } from "react";
import CategoryBadgeList from "components/CategoryBadgeList/CategoryBadgeList";
import ArticleTitle from "./ArticleTitle";
import CardAuthor from "components/Card/Author/CardAuthor";
import { AUTHORS } from "data/authors/authors";
import { DEMO_TAGS } from "api/tags/Tags";
import { PostDataType } from "data/types/post";
import { get } from "lodash";
import { getTaxonomiesByNames } from "api/tags/Category";

export interface ArticleHeaderProps {
  hiddenDesc?: boolean;
  titleMainClass?: string;
  className?: string;
  postData: PostDataType;
}

const ArticleHeader: FC<ArticleHeaderProps> = ({
  titleMainClass,
  hiddenDesc = false,
  className = "",
  postData,
}) => {
  const category_list = getTaxonomiesByNames(postData.categories as string[]);
  // console.log("category_list", category_list)
  return (
    <>
      <div className={`nc-ArticleHeader ${className}`}>
        <div className="space-y-5">
          <CategoryBadgeList
            itemClass="!px-3"
            categories={category_list} 
          />
          <ArticleTitle
            mainClass={titleMainClass}
            title={postData.title}
          />
          {!hiddenDesc && (
            <span className="block text-base text-neutral-500 md:text-lg dark:text-neutral-400 pb-1">
              {postData.desc}
            </span>
          )}
          <div className="w-full border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="flex flex-col sm:flex-row justify-between sm:items-end space-y-5 sm:space-y-0">
            <CardAuthor className="relative" author={postData.author} date={postData.date} />
            {postData.viewedCount && postData.viewedCount > 0 && postData.viewedCount > 100 && (
              <span className="text-neutral-500 dark:text-neutral-400 text-sm flex items-center">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-1.5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                </svg>
                {postData.viewedCount} views
              </span>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ArticleHeader;
