import ButtonPrimary from "components/Button/ButtonPrimary";
import React from "react";
import BlockchainSimulator from "components/BlockchainSimulator/BlockchainSimulator";
import HashGenerator from "components/HashGenerator/HashGenerator";
import Heading2 from "components/Heading/Heading2";
const BlockchainSimulatorPage: React.FC = () => (
  <div className="container relative pt-6 sm:pt-10 pb-16 lg:pt-20 lg:pb-28">
    <div className="p-5 mx-auto bg-white rounded-xl sm:rounded-3xl lg:rounded-[40px] shadow-lg sm:p-10 lg:p-16 dark:bg-neutral-900">
    <Heading2 emoji="">블록체인 시뮬레이션</Heading2>
      <BlockchainSimulator></BlockchainSimulator>
    </div>
  </div>
);

export default BlockchainSimulatorPage;
