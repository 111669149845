import { PostAuthorType } from "data/types/author";

export const getAuthorData = (): Array<PostAuthorType> => {
  return [
    {
      id: 0,
      firstName: "BQ",
      lastName: "",
      displayName: "BQ",
      email: "bq@google.com",
      avatar:
        "https://metadata-store.klaytnapi.com/4feea07c-8a03-da99-bab4-a11b0816c04a/de83bdb0-0552-cb74-7fd0-b02ddc33e908.png",
      bgImage:
        "https://images.pexels.com/photos/912410/pexels-photo-912410.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
      count: 1,
      href: "/",
      desc: `트위터: https://twitter.com/@BQyoutube\n
        유튜브: https://youtube.com/@BQyoutube\n
        텔레그램: https://t.me/BQTelegram\n
        홈페이지: https://bqyoutube.com`,
      jobName: "BQ Job",
    },
    {
      id: 1,
      firstName: "BQ",
      lastName: "Developer",
      displayName: "BQDeveloper",
      email: "bq@google.com",
      avatar:
        "https://metadata-store.klaytnapi.com/4feea07c-8a03-da99-bab4-a11b0816c04a/7f57e88d-574e-2e63-07ff-f31029879389.png",
      bgImage:
        "https://images.pexels.com/photos/912410/pexels-photo-912410.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
      count: 1,
      href: "/",
      desc: `트위터: https://twitter.com/@BQyoutube\n
        유튜브: https://youtube.com/@BQyoutube\n
        텔레그램: https://t.me/BQTelegram\n
        홈페이지: https://bqyoutube.com`,
      jobName: "BQ Job",
    },
    {
      id: 2,
      firstName: "the-block",
      lastName: "",
      displayName: "the-block",
      email: "bq@google.com",
      avatar: "",
      bgImage: "https://asset.brandfetch.io/idKIPMiOhQ/idJ6xJmEmY.jpeg",
      count: 1,
      href: "/",
      desc: ``,
      jobName: "",
    },
    {
      id: 3,
      firstName: "coindesk",
      lastName: "",
      displayName: "coindesk",
      email: "",
      avatar:
        "https://metadata-store.klaytnapi.com/4feea07c-8a03-da99-bab4-a11b0816c04a/dad23f40-7462-bc5d-87e1-10ec08cce486.jpeg",
      bgImage: "",
      count: 1,
      href: "/",
      desc: ``,
      jobName: "",
    },
    {
      id: 4,
      firstName: "coindesk-korea",
      lastName: "",
      displayName: "coindesk-korea",
      email: "",
      avatar:
        "https://metadata-store.klaytnapi.com/4feea07c-8a03-da99-bab4-a11b0816c04a/dad23f40-7462-bc5d-87e1-10ec08cce486.jpeg",
      bgImage: "",
      count: 1,
      href: "/",
      desc: ``,
      jobName: "",
    },
    {
      id: 5,
      firstName: "coinness",
      lastName: "",
      displayName: "coinness",
      email: "",
      avatar:
        "https://metadata-store.klaytnapi.com/4feea07c-8a03-da99-bab4-a11b0816c04a/f4b204db-e0e4-8f28-5d52-fb43a37e18da.png",
      bgImage: "",
      count: 1,
      href: "/",
      desc: ``,
      jobName: "",
    },
  ];
};
