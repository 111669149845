import ButtonPrimary from "components/Button/ButtonPrimary";
import React from "react";
import notFoundImage from '../../assets/page-not-found.png';  // Adjust the path as necessary

const Page404: React.FC = () => (
  <div className="nc-Page404">
    <div className="container relative py-16 lg:py-20">
      {/* HEADER */}
      <header className="text-center max-w-2xl mx-auto space-y-7">
        <img src={notFoundImage} alt="Page Not Found" className="mx-auto w-64 h-64 rounded-full"/>
        <h1 className="text-8xl md:text-9xl font-semibold tracking-widest">
          404
        </h1>
        <span className="block text-sm text-neutral-800 sm:text-base dark:text-neutral-200 tracking-wider font-medium">
          {`THE PAGE YOU WERE LOOKING FOR DOESN'T EXIST.`}
        </span>
        <ButtonPrimary href="/" className="mt-4">
          Return Home Page
        </ButtonPrimary>
      </header>
    </div>
  </div>
);

export default Page404;
