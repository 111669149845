import React, { useState, FC } from 'react';
import bq_client from 'api/client/bq_client';
import Cookies from 'react-cookie';
import { useLocation, useNavigate } from 'react-router-dom';import { setCookie } from 'utils/cookies/cookies';
;


interface LoginProps {}

interface LocationState {
  from: {
    pathname: string;
  };
}

const Login: FC<LoginProps> = () => {
  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();
  const location = useLocation();
  const { from } = (location.state as LocationState) || { from: { pathname: '/' } };

  const handleLogin = async () => {
    try {
      const response = await bq_client.post('/login',{
        username,
        password,
      });
      const token = response.data.token;
      if (token) {
        setCookie('token', token, { path: '/', expires: new Date(Date.now() + 604800000) }); // expires in 7 days
        setError(null);
        navigate(from.pathname); // Redirect to the original page
      } else {
        setError('Invalid token received');
      }
    } catch (error) {
      alert('Login failed');
    }
  };

  // Make sure the function body is properly formatted to return JSX
  return (
    <div className="login-container">
      <h2>Login</h2>
      <input
        type="text"
        placeholder="Username"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
      />
      <input
        type="password"
        placeholder="Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <button onClick={handleLogin}>Login</button>
      {error && <div className="error">{error}</div>}
    </div>
  );
};

export default Login;
