import { Cookies } from "react-cookie";
import { CookieSetOptions } from "universal-cookie";
import { v4 as uuidv4 } from "uuid";

const cookies = new Cookies();

export const setCookie = (
  name: string,
  value: string,
  options?: CookieSetOptions
) => {
  return cookies.set(name, value, { ...options });
};

export const getCookie = (name: string) => {
  return cookies.get(name);
};

export const setSessionId = () => {
  const sessionId = getCookie("sessionId");
  if (!sessionId) {
    const sessionId = uuidv4();
    setCookie("sessionId", sessionId, {
      path: "/",
      expires: new Date(Date.now() + 604800000),
      sameSite: "none",
      secure: true,
    });
  }
};
