import React, { FC, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { sha256 } from "js-sha256";
import ButtonPrimary from "components/Button/ButtonPrimary";
import Link from "components/Link";
import Image from "components/Image/Image";
import PostTypeFeaturedIcon from "components/PostTypeFeaturedIcon/PostTypeFeaturedIcon";
import CategoryBadgeList from "components/CategoryBadgeList/CategoryBadgeList";

interface Transaction {
  id: string;
  from: string;
  to: string;
  amount: number;
}

interface Block {
  id: string;
  transactions: Transaction[];
  previousHash: string;
  hash: string;
  initialPreviousHash: string;
}

type Balances = Record<string, number>;

const initialBalances: Balances = {
  A: 1000,
  B: 1000,
  C: 1000,
  D: 1000,
};

const BlockchainSimulator: FC = () => {
  const [blocks, setBlocks] = useState<Block[]>([]);

  const handleCreateBlock = () => {
    const newBlock: Block = {
      id: uuidv4(),
      transactions: [],
      previousHash: blocks.length > 0 ? blocks[blocks.length - 1].hash : "",
      hash: "",
      initialPreviousHash: blocks.length > 0 ? blocks[blocks.length - 1].hash : "",
    };
    const updatedBlocks = [...blocks, newBlock];
    setBlocks(updatedBlocks);
    updateBlockHash(newBlock.id);
  };

  const handleAddTransaction = (blockId: string) => {
    const newTransaction: Transaction = {
      id: uuidv4(),
      from: "",
      to: "",
      amount: 0,
    };
    setBlocks((prevBlocks) => {
      const updatedBlocks = prevBlocks.map((block) => {
        if (block.id === blockId) {
          return { ...block, transactions: [...block.transactions, newTransaction] };
        }
        return block;
      });
      updateBlockHash(blockId);
      return updatedBlocks;
    });
  };

  const handleUpdateTransaction = (
    blockId: string,
    transactionId: string,
    field: keyof Transaction,
    value: string
  ) => {
    setBlocks((prevBlocks) => {
      const updatedBlocks = prevBlocks.map((block) => {
        if (block.id === blockId) {
          const updatedTransactions = block.transactions.map((transaction) => {
            if (transaction.id === transactionId) {
              if (field === "amount") {
                const amount = parseInt(value, 10);
                const senderBalanceWithoutCurrentTransaction = getSenderBalanceWithoutCurrentTransaction(
                  transaction.from,
                  blockId,
                  transactionId
                );
                if (transaction.from && amount > senderBalanceWithoutCurrentTransaction) {
                  alert(`Insufficient balance for actor ${transaction.from}`);
                  return transaction;
                }
                return { ...transaction, amount };
              }
              return { ...transaction, [field]: value };
            }
            return transaction;
          });
          return { ...block, transactions: updatedTransactions };
        }
        return block;
      });
      updateBlockHash(blockId);
      return updatedBlocks;
    });
  };

  const getSenderBalanceWithoutCurrentTransaction = (
    sender: string,
    blockId: string,
    transactionId: string
  ) => {
    let balance = initialBalances[sender] || 0;
    blocks.forEach((block) => {
      if (block.id === blockId) {
        block.transactions.forEach((transaction) => {
          if (transaction.id !== transactionId) {
            if (transaction.from === sender) {
              balance -= transaction.amount;
            }
            if (transaction.to === sender) {
              balance += transaction.amount;
            }
          }
        });
      } else {
        block.transactions.forEach((transaction) => {
          if (transaction.from === sender) {
            balance -= transaction.amount;
          }
          if (transaction.to === sender) {
            balance += transaction.amount;
          }
        });
      }
    });
    return balance;
  };

  const handleDeleteTransaction = (blockId: string, transactionId: string) => {
    setBlocks((prevBlocks) => {
      const updatedBlocks = prevBlocks.map((block) => {
        if (block.id === blockId) {
          const updatedTransactions = block.transactions.filter(
            (transaction) => transaction.id !== transactionId
          );
          return { ...block, transactions: updatedTransactions };
        }
        return block;
      });
      updateBlockHash(blockId);
      return updatedBlocks;
    });
  };

  const updateBlockHash = (blockId: string) => {
    setBlocks((prevBlocks) => {
      const blockIndex = prevBlocks.findIndex((block) => block.id === blockId);
      if (blockIndex !== -1) {
        const block = prevBlocks[blockIndex];
        const previousHash = blockIndex > 0 ? prevBlocks[blockIndex - 1].hash : "";
        const transactionHash = block.transactions.reduce(
          (hash, transaction) =>
            hash +
            transaction.from +
            transaction.to +
            transaction.amount.toString(),
          ""
        );
        const hash = sha256(previousHash + transactionHash);

        const updatedBlock = { ...block, previousHash, hash };
        const updatedBlocks = [...prevBlocks];
        updatedBlocks[blockIndex] = updatedBlock;

        for (let i = blockIndex + 1; i < prevBlocks.length; i++) {
          const currentBlock = prevBlocks[i];
          const currentPreviousHash = updatedBlocks[i - 1].hash;
          const currentTransactionHash = currentBlock.transactions.reduce(
            (hash, transaction) =>
              hash +
              transaction.from +
              transaction.to +
              transaction.amount.toString(),
            ""
          );
          const currentHash = sha256(currentPreviousHash + currentTransactionHash);
          updatedBlocks[i] = { ...currentBlock, previousHash: currentPreviousHash, hash: currentHash };
        }

        return updatedBlocks;
      }
      return prevBlocks;
    });
  };

  const isHashValid = (blockIndex: number) => {
    const currentBlock = blocks[blockIndex];
    return currentBlock.previousHash === currentBlock.initialPreviousHash;
  };

  const getTotalTransactions = () => {
    return blocks.reduce((total, block) => total + block.transactions.length, 0);
  };

  const getTotalBlocks = () => {
    return blocks.length;
  };

  const calculateBalances = () => {
    let balances: Balances = { ...initialBalances };
    blocks.forEach((block) => {
      block.transactions.forEach((transaction) => {
        const { from, to, amount } = transaction;
        if (from && to && balances[from] >= amount) {
          balances[from] -= amount;
          balances[to] += amount;
        }
      });
    });
    return balances;
  };

  const getActorBalance = (actor: string) => {
    const balances = calculateBalances();
    return balances[actor] || 0;
  };

  return (
    <div className="container mx-auto py-16 lg:py-20">
      <div className="flex">
        {/* User Balances */}
        <div className="w-1/4 p-4">
          <div className="bg-white dark:bg-neutral-900 rounded-3xl p-4 shadow-lg">
            <h2 className="text-xl font-semibold mb-4">User Balances</h2>
            <ul className="space-y-2">
              {Object.keys(initialBalances).map((actor) => (
                <li key={actor} className="flex justify-between">
                  <span>{actor}:</span>
                  <span>{getActorBalance(actor)}</span>
                </li>
              ))}
            </ul>
          </div>
        </div>

        {/* Blockchain Simulator */}
        <div className="w-3/4 p-4">
          <div className="space-y-8">
            <div className="flex space-x-4 overflow-x-auto">
              {blocks.map((block, index) => (
                <div
                  key={block.id}
                  className={`bg-white dark:bg-neutral-900 rounded-3xl p-4 shadow-lg min-w-[300px] ${
                    !isHashValid(index) ? "border-4 border-red-500" : ""
                  }`}
                >
                  <h3 className="text-lg font-semibold mb-2">Block {index + 1}</h3>
                  <div className="mb-4">
                    <p className="text-gray-500">
                      Initial Previous Hash: {block.initialPreviousHash.slice(0, 15)}...
                    </p>
                    <p
                      className={`${
                        block.previousHash !== block.initialPreviousHash ? "text-red-500" : "text-blue-500"
                      }`}
                    >
                      Previous Hash: {block.previousHash.slice(0, 15)}...
                    </p>
                    <p className="text-blue-500">Hash: {block.hash.slice(0, 15)}...</p>
                  </div>
                  <div className="space-y-2">
                    <h4 className="text-md font-medium">Transactions</h4>
                    {block.transactions.map((transaction) => (
                      <div key={transaction.id} className="flex space-x-2">
                        <select
                          value={transaction.from}
                          onChange={(e) =>
                            handleUpdateTransaction(block.id, transaction.id, "from", e.target.value)
                          }
                          className="border rounded px-2 py-1 w-1/3"
                        >
                          <option value="">From</option>
                          {Object.keys(initialBalances).map((actor) => (
                            <option key={actor} value={actor}>
                              {actor}
                            </option>
                          ))}
                        </select>
                        <select
                          value={transaction.to}
                          onChange={(e) =>
                            handleUpdateTransaction(block.id, transaction.id, "to", e.target.value)
                          }
                          className="border rounded px-2 py-1 w-1/3"
                        >
                          <option value="">To</option>
                          {Object.keys(initialBalances).map((actor) => (
                            <option key={actor} value={actor}>
                              {actor}
                            </option>
                          ))}
                        </select>
                        <input
                          type="number"
                          value={transaction.amount}
                          onChange={(e) =>
                            handleUpdateTransaction(block.id, transaction.id, "amount", e.target.value)
                          }
                          className="border rounded px-2 py-1 w-1/3"
                        />
                        <button
                          onClick={() => handleDeleteTransaction(block.id, transaction.id)}
                          className="text-red-500 hover:text-red-700"
                        >
                          Delete
                        </button>
                      </div>
                    ))}
                    <ButtonPrimary onClick={() => handleAddTransaction(block.id)}>
                      Add Transaction
                    </ButtonPrimary>
                  </div>
                </div>
              ))}
              <ButtonPrimary onClick={handleCreateBlock} className="bg-blue-500 hover:bg-blue-600 min-w-[150px]">
                Create Block
              </ButtonPrimary>
            </div>

            <div className="bg-white dark:bg-neutral-900 rounded-3xl p-4 shadow-lg">
              <h2 className="text-xl font-semibold mb-4">Blockchain Summary</h2>
              <p>Total Blocks: {getTotalBlocks()}</p>
              <p>Total Transactions: {getTotalTransactions()}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlockchainSimulator;
