import axios from "axios";
import Cookies from "react-cookie";
import bq_client from "api/client/bq_client";
import { getCookie, setSessionId } from "utils/cookies/cookies";
import { set } from "lodash";

export type ActivityLogData = {
  path: string;
  description: string;
  timestamp: number;
};

export const sendActivityLog = async (
  activityLogData: ActivityLogData
): Promise<void> => {
  try {
    axios.defaults.withCredentials = true;

    // Retrieve the sessionId from the cookies
    const sessionId = getCookie("sessionId");
    if (!sessionId) {
      setSessionId();
    }

    // Define the API endpoint
    const API_PATH = "/activity-logs";

    // Send the POST request
    await bq_client.post(API_PATH, activityLogData, {
      withCredentials: true, // Ensures cookies are sent with requests,
      headers: {
        "Content-Type": "application/json",
        "Session-Id": sessionId,
      },
    });
  } catch (error) {
    if (axios.isAxiosError(error)) {
      // Handle Axios error here
      console.error("Axios error:", error.message);
      if (error.response) {
        console.error("Error data:", error.response.data);
      }
    } else {
      console.error("Unexpected error:", error);
    }
  }
};
