import React, { FC, ReactNode } from "react";
import Heading from "components/Heading/Heading";
import ButtonPrimary from "components/Button/ButtonPrimary";
import VideoCardSmall from "components/Card/VideoCardSmall";
import { PostDataType } from "data/types/post";
import { Link } from "react-router-dom";


//
export interface SectionGridPostsProps {
  posts?: PostDataType[];
  className?: string;
  gridClass?: string;
  heading?: ReactNode;
  subHeading?: ReactNode;
  headingIsCenter?: boolean;
  postCardName?:
    | "VideoCardSmall"
;
}

const SectionGridPosts: FC<SectionGridPostsProps> = ({
  posts = [],
  postCardName = "VideoCardSmall",
  className = "",
  gridClass = "",
  heading,
  subHeading,
  headingIsCenter,
}) => {
  const renderCard = (post: PostDataType) => {
    switch (postCardName) {
      case "VideoCardSmall":
        return <VideoCardSmall key={post.id} post={post} />;

    }
  };

  return (
    <div className={`nc-SectionGridPosts relative ${className}`}>
      <Heading desc={subHeading} isCenter={headingIsCenter}>
        {heading}
      </Heading>
      <div className={`grid gap-6 md:gap-8 ${gridClass}`}>
        {posts.map((post) => renderCard(post))}
      </div>
      <div className="flex mt-20 justify-center items-center font-bold">
        <ButtonPrimary href="https://www.youtube.com/@bqyoutube" loading targetBlank={true}>더보기</ButtonPrimary>
      </div>
    </div>
  );
};

export default SectionGridPosts;
