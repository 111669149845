import { SOCIALS_DATA, SocialType } from "data/types/social";
import React, { FC } from "react";

export interface MenuBarSocialsListProps {
  className?: string;
  itemClass?: string;
  socials?: SocialType[];
}

const socialsDemo: SocialType[] = SOCIALS_DATA;

export const SOCIALS_2 = socialsDemo;

const MenuBarSocialsList: FC<MenuBarSocialsListProps> = ({
  className = "",
  itemClass = "block",
  socials = socialsDemo,
}) => {
  return (
    <nav
      className={`nc-MenuBarSocialsList flex space-x-3 text-2xl text-neutral-6000 dark:text-neutral-300 ${className}`}
    >
      {socials.map((item, i) => (
        <a
          key={i}
          className={`${itemClass}`}
          href={item.href}
          target="_blank"
          rel="noopener noreferrer"
          title={item.name}
        >
          <img src={item.icon} alt={item.name} className="w-5 h-5" />  {/* Rendering the image */}
        </a>
      ))}
    </nav>
  );
};

export default MenuBarSocialsList;
