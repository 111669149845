import { DEMO_POSTS, fetchRecentPosts } from "api/posts/posts";
import SectionAds from "components/Advertisement/SectionAds";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionGridPosts from "components/Grid/SectionGridPosts";
import SectionLargeSlider from "components/Slider/SectionLargeSlider";
import { PostDataType } from "data/types/post";
import React, { useEffect, useState } from "react";
import { getYoutubeVideos } from "api/posts/videos/videoPosts";
import { useLanguage } from "utils/language/languageContext";
import { Helmet } from "react-helmet-async";

const PageHome = () => {
  const [recentPosts, setRecentPosts] = useState<PostDataType[] | null>(null);
  const [youtubeVideos, setYoutubeVideos] = useState<PostDataType[] | null>(
    null
  );

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const { selectedLanguage } = useLanguage();
  useEffect(() => {
    console.log("Home Page Selected language:", selectedLanguage);
    // You can perform actions when the language changes here
  }, [selectedLanguage]);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const data = await fetchRecentPosts();

        setRecentPosts(data);
        const youtubeData = await getYoutubeVideos();
        setYoutubeVideos(youtubeData);
      } catch (err) {
        // Check if err is an instance of Error
        if (err instanceof Error) {
          setError(err.message);
        } else {
          setError("An unknown error occurred");
        }
        setLoading(false);
      } finally {
        setLoading(false);
      }
    };

    fetchPosts();
  }, []);

  // Render loading state
  if (loading) {
    return <div>Loading...</div>;
  }

  // Render error state
  if (error || !recentPosts || !youtubeVideos) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="nc-PageHome relative">
      <Helmet>
        <title>BQ 홈페이지 - Home</title>
        <meta
          name="description"
          content="디지털 자산의 최신 트렌드 리서치, 일일 뉴스 브리핑, 가격 동향 등 모든 정보를 비큐 홈페이지에서 확인해보세요.
Check Crypto's Latest Trend Research, News Brief, Price Action and more through BQ Homepage"
        />
      </Helmet>
      <div className="container relative">
        <SectionLargeSlider
          className="pt-10 pb-16 md:py-16 lg:pb-28 lg:pt-20"
          posts={recentPosts}
        />
        <div className="relative py-16">
          <div className="relative py-16">
            <BackgroundSection />
            <SectionGridPosts
              headingIsCenter
              postCardName="VideoCardSmall"
              heading="BQ 유튜브 최신 영상"
              subHeading=""
              posts={youtubeVideos}
              gridClass="sm:grid-cols-2 lg:grid-cols-3"
            />
          </div>
          <div className="relative py-16">
            <SectionAds></SectionAds>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PageHome;
