// Pagination.jsx
import React, { FC } from 'react';

export interface PaginationProps {
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
}

const Pagination: FC<PaginationProps> = ({ currentPage, totalPages, onPageChange }) => {
  // Generate page numbers
  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  return (
    <div className="flex items-center justify-center space-x-1">
      {pageNumbers.map(number => (
        <button
          key={number}
          className={`inline-flex w-11 h-11 items-center justify-center rounded-full ${number === currentPage ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'} `}
          onClick={() => onPageChange(number)}
        >
          {number}
        </button>
      ))}
    </div>
  );
};

export default Pagination;
