import React, { FC } from "react";
import ADVERTISEMENT_IMG from "assets/images/ads/ads.png"
import OnLoadingImage from "components/Image/OnLoadingImage";

export interface SectionAdsProps {
  className?: string;
  imgAds?: string;
}

const SectionAds: FC<SectionAdsProps> = ({
  className = "",
  imgAds = ADVERTISEMENT_IMG,
}) => {
  return (
    <a
      href="/#"
      className={`nc-SectionAds block text-center mx-auto ${className}`}
    >
      <span className="text-xs text-neutral-500">- Advertisement -</span>
      <OnLoadingImage className="mx-auto" src={imgAds} alt="ads" />
    </a>
  );
};

export default SectionAds;
