// SEOMetaTag.tsx
import { OGSProperties } from "data/types/post";
import React from "react";
import { Helmet } from "react-helmet-async";

// Define a type for the component's props
interface SEOMetaTagProps {
  ogs: OGSProperties;
  locale?: string;
  url?: string;
}

const SEOMetaTag: React.FC<SEOMetaTagProps> = ({
  ogs: {
    title = "",
    description = "",
    keywords = "",
    image = "",
    url = "https://bqyoutube.com",
  },
  locale = "ko_KR",
}) => {
  return (
    <Helmet>
      {title && <title>{title}</title>}
      {description && <meta name="description" content={description} />}
      {keywords && <meta name="keywords" content={keywords} />}

      <meta property="og:type" content="website" />
      <meta property="og:site_name" content="BQ's Website" />
      {title && <meta property="og:title" content={title} />}
      {description && <meta property="og:description" content={description} />}
      {image && <meta property="og:image" content={image} />}
      {url && <meta property="og:url" content={url} />}

      {title && <meta name="twitter:title" content={title} />}
      {description && (
        <meta name="twitter:description" content={description}></meta>
      )}
      {image && <meta name="twitter:image" content={image} />}
      {locale && <meta property="og:locale" content={locale} />}

      <link rel="canonical" href={url} />
    </Helmet>
  );
};

export default SEOMetaTag;
