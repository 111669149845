import React from "react";
import MarkdownEditorPage from "components/MarkdownEditor/MarkdownEditor";
import { useParams } from "react-router-dom";

const ArticlePreviewPage = () => {
  const { postId } = useParams<{ postId: string }>();

  return (
    <div className="container mt-10">
      <MarkdownEditorPage postId={postId || undefined} />;
    </div>
  );
};

export default ArticlePreviewPage;
