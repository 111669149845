import MainPagePostCardLarge from "components/Card/MainPagePostCardLarge";
import Heading from "components/Heading/Heading";
import { PostDataType } from "data/types/post";
import React, { FC, useState } from "react";

export interface SectionLargeSliderProps {
  className?: string;
  heading?: string;
  posts: PostDataType[];
}

const SectionLargeSlider: FC<SectionLargeSliderProps> = ({
  posts,
  heading = "BQ's 인사이트",
  className = "",
}) => {
  const [indexActive, setIndexActive] = useState(0);

  const handleClickNext = () => {
    setIndexActive((state) => {
      if (state >= posts.length - 1) {
        return 0;
      }
      return state + 1;
    });
  };

  const handleClickPrev = () => {
    setIndexActive((state) => {
      if (state === 0) {
        return posts.length - 1;
      }
      return state - 1;
    });
  };


  return (
    <div className={`nc-SectionLargeSlider relative ${className}`}>
      {!!heading && <Heading>{heading}</Heading>}
      
      {posts.map((item, index) => {
        if (indexActive !== index) return null;
        return (
          <MainPagePostCardLarge
            key={index}
            onClickNext={handleClickNext}
            onClickPrev={handleClickPrev}
            post={item}
          />
        );
      })}
    </div>
  );
};

export default SectionLargeSlider;
