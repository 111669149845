import MainNavigationBar from "components/Navigation/MainNavigationBar";
import React, { FC } from "react";

export interface HeaderProps {}

const Header: FC<HeaderProps> = () => {
  return (
    <div className="nc-Header sticky top-0 w-full z-40">
      <MainNavigationBar />
    </div>
  );
};

export default Header;
