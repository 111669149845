import ArticleListPage from "components/ArticleListPage/ArticleListPage";
import React from "react";

// TODO: get page content and pass it to ArticleLayout, ArticleHeader
const AirdropArticleList = () => {
  return (
    // NOTE: remove w-full from div below if you want to use Widgets below
    <div className="container mt-15 pt-10 lg:pt-20">
      <ArticleListPage
        postCardName="articleCard"
        className="pb-16 lg:pb-28"
        postType="airdrop"
      ></ArticleListPage>
    </div>
  );
};

export default AirdropArticleList;
