import { Route } from "routers/types";
import CategoryTags from "./category_tags.json";
import { TaxonomyType } from "data/types/post";

const DEMO_TAGS: TaxonomyType[] = CategoryTags.map((item) => ({
  ...item,
  taxonomy: "tag",
  href: item.href as Route,
}));

export { DEMO_TAGS };
