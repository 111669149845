import { PostDataType } from "data/types/post";
import React, { FC } from "react";

export interface PostTypeFeaturedIconProps {
  className?: string;
  postType?: PostDataType["postType"];
  onClick?: () => void;
  wrapSize?: string;
  iconSize?: string;
}

const PostTypeFeaturedIcon: FC<PostTypeFeaturedIconProps> = ({
  className = "",
  postType = "standard",
  onClick,
  wrapSize = "w-11 h-11",
  iconSize = "w-6 h-6",
}) => {
  const renderMediaIcon = () => {
    if (postType === "video") {
      return (
        <svg
          className={iconSize}
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M17.13 7.9799C20.96 10.1899 20.96 13.8099 17.13 16.0199L14.04 17.7999L10.95 19.5799C7.13 21.7899 4 19.9799 4 15.5599V11.9999V8.43989C4 4.01989 7.13 2.2099 10.96 4.4199L13.21 5.7199"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    }

    if (postType === "advertiseSite") {
      return (
        <svg
          className={iconSize}
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14 10V3.5M10 10V3.5M3.5 10H20.5M17 21.5H7C4.23858 21.5 2 19.2614 2 16.5V7.5C2 4.73858 4.23858 2.5 7 2.5H17C19.7614 2.5 22 4.73858 22 7.5V16.5C22 19.2614 19.7614 21.5 17 21.5Z"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    }

    return null;
  };

  return (
    <div
      className={`nc-PostTypeFeaturedIcon ${className}`}
      data-nc-id="PostTypeFeaturedIcon"
      onClick={onClick}
    >
      {!!postType && postType !== "standard" && (
        <span
          className={`bg-neutral-900 bg-opacity-60 rounded-full flex  items-center justify-center text-xl text-white border border-white ${wrapSize}`}
        >
          {renderMediaIcon()}
        </span>
      )}
    </div>
  );
};

export default PostTypeFeaturedIcon;
