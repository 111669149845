// original name : Card10V2
import React, { FC, useState } from "react";
import Link from "components/Link";
import { PostDataType } from "data/types/post";
import PostFeaturedVideo from "./Post/PostFeaturedVideo";
import CardAuthor from "./Author/CardAuthor";
import CardAuthorVideo from "./Author/CardAuthorVideo";

export interface VideoCardSmallProps {
  className?: string;
  post: PostDataType;
}

const VideoCardSmall: FC<VideoCardSmallProps> = ({ className = "h-full", post }) => {
  const { href, categories } = post;
  const [isHover, setIsHover] = useState(false);
  return (
    <div
      className={`nc-VideoCardSmall relative flex flex-col ${className}`}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <div className="block group rounded-3xl flex-shrink-0 relative w-full aspect-w-16 aspect-h-12 sm:aspect-h-9 overflow-hidden z-0">
        <div>
          <PostFeaturedVideo post={post} isHover={isHover} />
        </div>

        <Link
          href={href}
          className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 transition-opacity"
        ></Link>
      </div>

      <div className="space-y-2.5 md:mt-3 -mt-3 px-4">
        <CardAuthorVideo className="relative" videoTitle={post.title} author={post.author} date={post.date} href={post.videoUrl || "/"}/>
      </div>
    </div>
  );
};

export default VideoCardSmall;
