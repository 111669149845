import React, { useState, useEffect } from "react";
import { HTMLAttributes } from "react";
import ReactMarkdown from "react-markdown";
import styled from "styled-components";
import rehypeHighlight from "rehype-highlight";
import remarkGfm from "remark-gfm";
import "highlight.js/styles/atom-one-dark.css";
import { nord } from "react-syntax-highlighter/dist/esm/styles/prism";
const SyntaxHighlighter = require("react-syntax-highlighter").Prism;

const MarkdownWrapper = styled.div`
  /* General markdown styles */
  p,
  li {
    font-family: var(--font-body);
    font-weight: 500;
  }

  h1 {
    font-family: var(--font-display);
    font-weight: 600;
  }
  h2 {
    font-family: var(--font-display);
    font-weight: 600;
  }
  h3 {
    font-family: var(--font-display);
    font-weight: 600;
  }
  h4,
  h5,
  h6 {
    font-family: var(--font-display);
    font-weight: 500;
  }

  /* Center images */
  img {
    display: block;
    margin: 0 auto;
    max-width: 100%; // Ensure large images do not overflow the container
  }

  /* Disable text selection and copying */
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
`;

const CodeBlockWrapper = styled.div`
  position: relative;
  pre {
    overflow-x: auto;
  }
`;

const CopyButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #333;
  color: #fff;
  border: none;
  padding: 0.25rem 0.5rem;
  font-size: 0.8rem;
  cursor: pointer;
`;

interface HeadingProps {
  level: 1 | 2 | 3 | 4 | 5 | 6;
  children: React.ReactNode;
}

const Heading: React.FC<HeadingProps> = ({ level, children }) => {
  const Tag = `h${level}` as keyof JSX.IntrinsicElements;

  let additionalStyle = {};
  if (level === 1) {
    additionalStyle = { marginTop: "2em", marginBottom: "1em" };
    // console.log("h1", children)
  } else if (level === 2) {
    additionalStyle = { marginTop: "1.5em", marginBottom: "1em" };
    // console.log("h2", children)
  } else if (level === 3) {
    additionalStyle = { marginTop: "1em", marginBottom: "1em" };
    // console.log("h3", children)
  }

  return <Tag style={additionalStyle}>{children}</Tag>;
};

interface CodeComponentProps extends HTMLAttributes<HTMLElement> {
  node?: any;
  inline?: boolean;
  className?: string;
  children?: any;
}

interface ArticleBodyProps {
  content?: string;
}

const getChildrenText = (children: React.ReactNode): string => {
  if (typeof children === "string") {
    return children;
  }
  if (Array.isArray(children)) {
    return children.map((child) => getChildrenText(child)).join("");
  }
  if (React.isValidElement(children) && children.props) {
    return getChildrenText(children.props.children);
  }
  return "";
};

// FIXME: delete this if CodeComponent is working well. originally, it was used in
// const components = {
//   h1: (props: any) => <Heading level={1}  {...props} />,
//   h2: (props: any) => <Heading level={2} {...props} />,
//   h3: (props: any) => <Heading level={3} {...props} />,
//   h4: (props: any) => <Heading level={4} {...props} />,
//   h5: (props: any) => <Heading level={5} {...props} />,
//   h6: (props: any) => <Heading level={6} {...props} />,
//   code: workingCode,
//   // ... any other components you want to override
// };
// const workingCode = ({ node, inline, className, children, ...props }: CodeComponentProps) => {
//   const match = /language-(\w+)/.exec(className || "");

//   const textContent = getChildrenText(children);

//   if (match) {
//     // Code block with specific language
//     return (
//       <CodeBlockWrapper>
//         <SyntaxHighlighter
//           children={textContent}
//           style={nord}
//           language={match[1]}
//           PreTag="div"
//           {...props}
//         />
//         <CopyButton onClick={() => navigator.clipboard.writeText(textContent)}>Copy</CopyButton>
//       </CodeBlockWrapper>
//     );
//   } else {
//     // Code block without specific language
//     return (
//       <code style={{ background: "var(--highlight-color)", padding: "2px" }} {...props}>
//         {textContent}
//       </code>
//     );
//   }
// }

const CodeComponent: React.FC<CodeComponentProps> = ({
  node,
  inline,
  className,
  children,
  ...props
}) => {
  const [isCopied, setIsCopied] = useState(false);
  const match = /language-(\w+)/.exec(className || "");
  const textContent = getChildrenText(children);

  const handleCopy = async () => {
    await navigator.clipboard.writeText(textContent);
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  };

  return match ? (
    <CodeBlockWrapper>
      <SyntaxHighlighter
        children={textContent}
        style={nord}
        language={match[1]}
        PreTag="div"
        {...props}
      />
      <CopyButton onClick={handleCopy}>
        {isCopied ? "Copied" : "Copy"}
      </CopyButton>
    </CodeBlockWrapper>
  ) : (
    <code
      style={{ background: "var(--highlight-color)", padding: "2px" }}
      {...props}
    >
      {textContent}
    </code>
  );
};

const components = {
  a: (props: any) => <a target="_blank" rel="noopener noreferrer" {...props} />, // Custom renderer for links
  h1: (props: any) => <Heading level={1} {...props} />,
  h2: (props: any) => <Heading level={2} {...props} />,
  h3: (props: any) => <Heading level={3} {...props} />,
  h4: (props: any) => <Heading level={4} {...props} />,
  h5: (props: any) => <Heading level={5} {...props} />,
  h6: (props: any) => <Heading level={6} {...props} />,
  code: CodeComponent,
  // ... any other components you want to override
};

const ArticleBody = ({ postData }: { postData: ArticleBodyProps }) => {
  return (
    <MarkdownWrapper>
      <ReactMarkdown
        rehypePlugins={[rehypeHighlight, remarkGfm]}
        components={components} // ignore this lint error
      >
        {postData.content}
      </ReactMarkdown>
    </MarkdownWrapper>
  );
};

export default ArticleBody;
