import __posts from "./posts.json";
import __news from "./news.json";
import { PostDataType } from "data/types/post";
import { DEMO_CATEGORIES, getTaxonomiesByNames } from "api/tags/Category";
import { AUTHORS } from "data/authors/authors";
import axios from "axios";
import { formatDate } from "utils/date/dateutil";
import { getAuthorData } from "api/authors/Authors";
import { getJWTToken } from "utils/auth/jwt";
import { getCookie, setCookie, setSessionId } from "utils/cookies/cookies";
import { v4 as uuidv4 } from "uuid";

// FOR MAIN DEMO
// TODO: link category and category_id in post
const DEMO_POSTS = __posts.map((post, index): PostDataType => {
  //  ##########  GET CATEGORY BY CAT ID ######## //
  const categories = post.categoriesId.map(
    (id) => DEMO_CATEGORIES.filter((taxonomy) => taxonomy.id === id)[0]
  );

  return {
    ...post,
    id: `POSTS_${index + 1}`,
    author: AUTHORS.filter((user) => user.id === post.authorId)[0],
    categories: [categories[0]], // TODO: get from post data
  } as PostDataType;
});

const DEMO_NEWS = __news.map((post, index): PostDataType => {
  //  ##########  GET CATEGORY BY CAT ID ######## //
  const categories = post.categoriesId.map(
    (id) => DEMO_CATEGORIES.filter((taxonomy) => taxonomy.id === id)[0]
  );

  return {
    ...post,
    id: `POSTS_${index + 1}`,
    author: AUTHORS.filter((user) => user.id === post.authorId)[0],
    categories: [categories[0]], // TODO: get from post data
  } as PostDataType;
});


const authors = getAuthorData();
// Define the base URL for your API
const API_BASE_URL = process.env.REACT_APP_API_HOST;
// const API_BASE_URL = "http://localhost:8080";


// Function to get a post by ID
export const getPostById = async (
  id: string | number
): Promise<PostDataType> => {
  try {
    const response = await axios.get(`${API_BASE_URL}/posts/${id}`);
    updatePostViewCount(id as string);
    // console.log("response", response);
    const post = response.data;
    // console.log("post", {
    //   ...post,
    //   postType: "standard",
    //   date: formatDate(post.date),
    //   categories: getTaxonomiesByNames(post.categories as string[]),
    // });
    return {
      ...post,
      originalContent: post.originalContent,
      author:
        authors.filter((author) => author.displayName === post.author)[0] ||
        authors[0],
      date: formatDate(post.date),
    } as PostDataType;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      // Handle Axios error here
      console.error("Axios error:", error.message);
      if (error.response) {
        console.error("Error data:", error.response.data);
      }
    } else {
      // Handle non-Axios error here
      console.error("Unexpected error:", error);
    }
    throw error;
  }
};

export const fetchRecentPosts = async (): Promise<PostDataType[]> => {
  try {
    const response = await axios.get<PostDataType[]>(
      `${API_BASE_URL}/posts/recent?size=20&postType=standard,airdrop`
    );
    console.log("response", response);
    const result = response.data.map((post: any) => {
      return {
        ...post,
        date: formatDate(post.date),
        categories: getTaxonomiesByNames(post.categories),
        author: authors.filter(
          (author) => author.displayName === post.author
        )[0],
      };
    });
    // console.log("recent result", JSON.stringify(result));
    return result;
  } catch (error) {
    // Assuming the error is an instance of AxiosError which has the `response` property.
    // You might want to adjust this if your error objects are different.
    if (axios.isAxiosError(error)) {
      // Handle error response (e.g., log it or transform it)
      throw new Error(
        error.response?.data || "An error occurred while fetching the posts"
      );
    }
    // If it's not an AxiosError, it could be something else, like a network issue.
    throw new Error("Network error or other issue while fetching the posts");
  }
};

// TODO: implement fetchPostsByAuthor
export const fetchPosts = async (size: Number, postType: string): Promise<PostDataType[]> => {
  try {
    const response = await axios.get<PostDataType[]>(
      `${API_BASE_URL}/posts/recent?size=${size}&postType=${postType}`
    );

    const result = response.data.map((post: any) => {
      return {
        ...post,
        date: formatDate(post.date),
        categories: getTaxonomiesByNames(post.categories),
        author: authors.filter(
          (author) => author.displayName === post.author
        )[0],
      };
    });
    // console.log("recent result", JSON.stringify(result));
    return result;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error(
        error.response?.data || "An error occurred while fetching the posts"
      );
    }
    throw new Error("Network error or other issue while fetching the posts");
  }
};

// Note: author = displayName
export const fetchPostsByAuthor = async (author: String, size: Number, postType: string): Promise<PostDataType[]> => {
  try {
    const response = await axios.get(
      `${API_BASE_URL}/posts/authors/${author}?size=${size}&postType=${postType}`
    );
    
    const result = response.data.content.map((post: any) => {
      // console.log("post", post.title, post.date)
      return {
        ...post,
        categories: getTaxonomiesByNames(post.categories),
        author: authors.filter(
          (author) => author.displayName === post.author
        )[0],
      };
    });
    // console.log("recent result", JSON.stringify(result));
    return result;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error(
        error.response?.data || "An error occurred while fetching the posts"
      );
    }
    throw new Error("Network error or other issue while fetching the posts");
  }
};


export const deletePostById = async (postId: string): Promise<void> => {
  try {
    const jwtToken = getJWTToken(); // Retrieve the JWT token

    // Making a DELETE request with JWT token in the Authorization header
    await axios.delete(`${API_BASE_URL}/posts/${postId}`, {
      headers: {
        Authorization: `Bearer ${jwtToken}`, // Include the JWT token in the Authorization header
      },
    });

    console.log(`Post with ID ${postId} deleted successfully`);
  } catch (error) {
    // Handle Axios errors
    if (axios.isAxiosError(error)) {
      // You can handle specific status codes or error messages if needed
      throw new Error(
        error.response?.data ||
          `An error occurred while deleting the post with ID ${postId}`
      );
    }
    // Handle non-Axios errors
    throw new Error(
      `Network error or other issue while deleting the post with ID ${postId}`
    );
  }
};

export type CreatePostResponse = {
  // Define properties based on what your API returns
  success: boolean;
  message?: string;
  postId?: string;
};

export type CreatePostData = {
  author: string;
  date: string;
  categories: string[];
  title: string;
  featuredImage: string;
  desc: string;
  content: string;
  like: { count: number; isLiked: boolean };
  bookmark: { count: number; isBookmarked: boolean };
  commentCount: number;
  viewedCount: number;
  readingTime: number;
  postType: string;
  videoUrl: string | null;
};
// const apiHost = process.env.REACT_APP_API_HOST;
const apiHost = "https://www.bqyoutube.com/api/blog";

export const createPost = async (
  postData: CreatePostData
): Promise<CreatePostResponse> => {
  try {
    const authToken = getJWTToken();
    const response = await axios.post(`${apiHost}/posts`, postData, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    });
    console.log("Requesting", `${apiHost}/posts`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    });
    console.log("response", response);

    // Check response status code and return data
    if (response.status === 200 || response.status === 201) {
      // Handle the success response here
      return {
        success: true,
        message: "Post created successfully",
        postId: response.data.id, // Assuming the response contains an ID
      };
    } else {
      // Handle any other responses here
      return {
        success: false,
        message: `Unexpected status code: ${response.status}`,
      };
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      // Handle Axios error here
      const serverResponse = error.response?.data || {};
      return {
        success: false,
        message:
          serverResponse.message || "An error occurred during the API call",
      };
    } else {
      // Handle non-Axios error here
      return {
        success: false,
        message: "An unknown error occurred",
      };
    }
  }
};

// Add this function to your existing file

export const updatePostViewCount = async (postId: string): Promise<void> => {
  try {
    const API_PATH = `/posts/${postId}/view`;

    // Retrieve the sessionId from the cookies
    const sessionId = getCookie("sessionId");
    if (!sessionId) {
      setSessionId();
    }
    console.log("sessionId", sessionId);
    axios.defaults.withCredentials = true;
    await axios.put(`${apiHost}${API_PATH}`, null, {
      headers: { "Session-Id": sessionId },
      withCredentials: true,
    });
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error("Axios error:", error.message);
      if (error.response) {
        console.error("Error data:", error.response.data);
      }
    } else {
      console.error("Unexpected error:", error);
    }
  }
};

export default getPostById;

export { DEMO_POSTS, DEMO_NEWS };
