import { Route } from "routers/types";
import CategoryTags from "./category_tags.json";
import { TaxonomyType } from "data/types/post";

const DEMO_CATEGORIES: TaxonomyType[] = CategoryTags.map((item) => ({
  ...item,
  taxonomy: "category",
  href: item.href as Route,
}));

export const getTaxonomiesByNames = (names: string[]): TaxonomyType[] => {
  return DEMO_CATEGORIES.filter(category => names.includes(category.name));
};

export { DEMO_CATEGORIES };
