import MyRoutes from "routers";
import React, { useEffect } from "react";
import { CookiesProvider } from "react-cookie";
import { LanguageProvider } from "utils/language/languageContext";
import { HelmetProvider } from "react-helmet-async";
function App() {
  useEffect(() => {
    // other initialization code
  }, []);
  return (
    <div className="bg-[#f8f8f8] text-base dark:bg-neutral-900/95 text-neutral-900 dark:text-neutral-200 font-body">
      <HelmetProvider>
        <CookiesProvider>
          <LanguageProvider>
            <MyRoutes />
          </LanguageProvider>
        </CookiesProvider>
      </HelmetProvider>
    </div>
  );
}

export default App;
