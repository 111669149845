import ButtonPrimary from "components/Button/ButtonPrimary";
import React from "react";
import implementingImage from "../../assets/page-implementing.png"; // Adjust the path as necessary
import AdvertiseCard from "components/Card/Advertise/AdvertiseCard";
import { DEMO_POSTS } from "api/posts/posts";
import Heading2 from "components/Heading/Heading2";

const AdvertisePage: React.FC = () => (
  <div className={`nc-LayoutPage relative`}>
    <div
      className={`absolute h-[400px] top-0 left-0 right-0 w-full bg-primary-100 dark:bg-neutral-800 bg-opacity-25 dark:bg-opacity-40`}
    />
    <div className="container relative pt-6 sm:pt-10 pb-16 lg:pt-20 lg:pb-28">
      <div className="p-5 mx-auto bg-white rounded-xl sm:rounded-3xl lg:rounded-[40px] shadow-lg sm:p-10 lg:p-16 dark:bg-neutral-900">
        <header className="text-center max-w-2xl mx-auto - mb-14 sm:mb-16 lg:mb-24">
          <Heading2 emoji="">BQ's 플랫폼 픽</Heading2>
          <span className="block text-sm mt-2 text-neutral-700 sm:text-base dark:text-neutral-200"></span>
        </header>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-6 md:gap-8 mt-3">
          <AdvertiseCard post={DEMO_POSTS[6]}></AdvertiseCard>
          <AdvertiseCard post={DEMO_POSTS[7]}></AdvertiseCard>
          <AdvertiseCard post={DEMO_POSTS[8]}></AdvertiseCard>
          <AdvertiseCard post={DEMO_POSTS[5]}></AdvertiseCard>
          <AdvertiseCard post={DEMO_POSTS[5]}></AdvertiseCard>
          <AdvertiseCard post={DEMO_POSTS[5]}></AdvertiseCard>
        </div>
      </div>
    </div>
  </div>
);

export default AdvertisePage;
