import { format, parseISO, parse} from 'date-fns';

const formatString = "MMMM, d, yyyy, HH:mm";
const formatString2 = "yyyy/M/d HH:mm";

export function formatDate(dateString: string): string {
  const date = parseISO(dateString);
  return format(date, formatString);
}
export function formatDate2(dateString: string): string {
  const date = parseISO(dateString);
  return format(date, formatString2);
}
export function convertToISO(formattedDateString: string): string {
  // Parse the formatted date string back into a Date object
  const date = parse(formattedDateString, formatString, new Date());

  // Convert to ISO string
  return date.toISOString();
}

export function convertFormattedStringToDate(formattedDateString: string): Date {
  // Parse the formatted date string back into a Date object
  const date = parse(formattedDateString, formatString, new Date());
  // Convert to ISO string
  return date
}


export function formatRelativeTime(isoDate: string): string {
  const currentDate = new Date();
  const localDate = new Date(isoDate);

  const timeDifferenceInMilliseconds = currentDate.getTime() - localDate.getTime();
  const minutesDifference = Math.floor(timeDifferenceInMilliseconds / (1000 * 60));
  const hoursDifference = Math.floor(minutesDifference / 60);
  const daysDifference = Math.floor(hoursDifference / 24);

  if (daysDifference > 0) {
    return `${daysDifference}일 전`;
  } else if (hoursDifference > 0) {
    return `${hoursDifference}시간 전`;
  } else {
    return `${minutesDifference}분 전`;
  }
} 

