import { AvatarProps } from "components/Avatar/Avatar";
import Link from "components/Link";
import { CustomLink } from "data/types/cuostomLinks";
import React, { FC } from "react";

export interface WidgetHeading1Props {
  className?: string;
  title: string;
  viewAll: CustomLink;
  hiddenAvatar?: boolean;
  avatar?: React.ReactNode;
}

const WidgetHeading1: FC<WidgetHeading1Props> = ({
  className = "",
  title,
  hiddenAvatar = true,
  avatar = null,
  viewAll,
}) => {
  const titleStyles = hiddenAvatar ? {} : { marginLeft: "5px" };

  return (
    <div
      className={`nc-WidgetHeading1 flex items-center justify-between p-4 xl:p-5 border-b border-neutral-200 dark:border-neutral-700 ${className}`}
    >
      <div className="flex items-center"> {/* Ensure vertical alignment for title and avatar */}
        {!hiddenAvatar && (
          <div className="flex items-center mr-2"> 
            {avatar}
          </div>
        )}
        <h2
          className="text-lg text-neutral-900 dark:text-neutral-100 font-semibold"
        >
          {title}
        </h2>
      </div>

      {!!viewAll.href && (
        <Link
          className="flex items-center flex-shrink-0 block text-primary-700 dark:text-primary-500 font-semibold text-sm" // Align link vertically
          target={viewAll.targetBlank ? "_blank" : undefined}
          rel="noopener noreferrer"
          href={viewAll.href}
        >
          {viewAll.label}
        </Link>
      )}
    </div>
  );
};

export default WidgetHeading1;
