import ButtonPrimary from "components/Button/ButtonPrimary";
import React, { useEffect, useState } from "react";
import notFoundImage from "../../assets/page-not-found.png"; // Adjust the path as necessary
import Heading2 from "components/Heading/Heading2";
import Tab from "components/Tab/Tab";
import TabItem from "components/Tab/TabItem";
import CryptoTopLowTable from "components/Table/CryptoTopLowTable";
import CryptoTrendView from "./cryptotrendview";
import { TopLowData, fetchTopLowData } from "api/cryptocurrency/toplow";

const TABS: { [label: string]: string } = {
  "30분": "30",
  "1시간": "60",
  "4시간": "240",
  "12시간": "12H",
  "24시간": "1D",
};

const CryptoTrendPage: React.FC = () => {
  return (
    <div className=" relative pt-6 sm:pt-10 pb-16 lg:pt-20 lg:pb-28 max-w-6xl mx-auto">
      <div className="flex flex-col items-center justify-center">
        <div className="crypto-trend-view">
          <CryptoTrendView
            title="바이낸스 급등락 코인(선물)"
            exchange="binance"
            trade_type="perp"
            event_log="binance_top_low_perp"
          ></CryptoTrendView>
        </div>
        <div className="mb-4"></div>
        <div className="crypto-trend-view">
          <CryptoTrendView
            title="바이낸스 급등락 코인(현물)"
            exchange="binance"
            trade_type="spot"
            event_log="binance_top_low"
          ></CryptoTrendView>
        </div>
        <div className="mb-4"></div>
        <div className="crypto-trend-view">
          <CryptoTrendView
            title="업비트 급등락 코인(현물)"
            exchange="upbit"
            trade_type="spot"
            event_log="upbit_top_low"
          ></CryptoTrendView>
        </div>
      </div>
    </div>
  );
};

export default CryptoTrendPage;
