import { getAuthorData } from "api/authors/Authors";
import BQ from "assets/images/logos/bq-logo.png";
import BQDeveloper from "assets/images/logos/bq-developer-logo.png";
import { PostAuthorType } from "data/types/author";
import { Route } from "routers/types";

let images = [BQ, BQDeveloper];

const __authors = getAuthorData();

const AUTHORS: PostAuthorType[] = __authors.map((item, index) => ({
  ...item,
  // NOTE: this note determines which avatar image. 
  // FIXME: use function to get image instead of index. I think it's better to use name to get the image
  avatar: images[index], 
  href: item.href as Route,
}));

export { AUTHORS };
