import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_API_HOST;

// Define the TypeScript type for TopLowData
export interface TopLowData {
  originalSymbol?: string | null;
  type?: string | null;
  exchange?: string | null;
  interval?: string | null;
  timestamp?: number | null;
  symbol?: string | null;
  startPrice?: number | null;
  lastPrice?: number | null;
  startDate?: string | null;
  endDate?: string | null;
  priceDiff?: number | null;
  priceDiffPercent?: number | null;
}

export const fetchTopLowData = async (
  type: string,
  exchange: string,
  interval: string,
  timestamp: number
): Promise<TopLowData[]> => {
  try {
    const response = await axios.get<TopLowData[]>(
      `${API_BASE_URL}/api/top-low-data`,
      { params: { type, exchange, interval, timestamp } }
    );

    // Assuming the API returns an array of TopLowData
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error(
        error.response?.data.message ||
          "An error occurred while fetching the top low data"
      );
    }
    throw new Error(
      "Network error or other issue while fetching the top low data"
    );
  }
};
