import React, { FC } from "react";
import Avatar from "components/Avatar/Avatar";
// import Link from "components/Link"; // Comment out Link for now, to be used again once author pages are implemented
import { PostDataType } from "data/types/post";

export interface CardAuthorVideoProps
  extends Pick<PostDataType, "date" | "author"> {
  className?: string;
  readingTime?: PostDataType["readingTime"];
  videoTitle?: string;
  href: string; // Comment out href as it's not currently used
  hoverReadingTime?: boolean;
}

const CardAuthorVideo: FC<CardAuthorVideoProps> = ({
  className = "",
  author,
  videoTitle,
  readingTime,
  href, // Comment out href as it's not currently used
  hoverReadingTime = true,
}) => {
  const { displayName, avatar } = author;
  // console.log("author in avatar card", avatar);
  return (
    // Comment the Link and add a div with TODO for future implementation
    <div
      className={`CardAuthorVideo relative inline-flex items-center ${className}`}
    >
      {/* TODO: Convert this div back to Link after author page implementation */}
      {/* <Link href={href} className="..."> */}
      <Avatar
        sizeClass="h-10 w-10 text-base"
        containerClassName="flex-shrink-0 mr-3"
        radius="rounded-full"
        imgUrl={avatar}
        userName={displayName}
      />
      <div>
        <h2
          className={`text-sm text-neutral-700 hover:text-black dark:text-neutral-300 dark:hover:text-white font-medium`}
        >
          {videoTitle}
        </h2>
        <span
          className={`flex items-center mt-1 text-xs text-neutral-500 dark:text-neutral-400`}
        >
          <span>{displayName}</span>
          {readingTime && (
            <>
              <span
                className={`hidden lg:inline mx-1 transition-opacity ${
                  hoverReadingTime ? "opacity-0 group-hover:opacity-100" : ""
                }`}
              >
                ·
              </span>
              <span
                className={`hidden lg:inline transition-opacity ${
                  hoverReadingTime ? "opacity-0 group-hover:opacity-100" : ""
                }`}
              >
                {readingTime} min read
              </span>
            </>
          )}
        </span>
      </div>
      {/* </Link> */}
    </div>
  );
};

export default CardAuthorVideo;
