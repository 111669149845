import { useLocation } from "react-router-dom";

/**
  a custom React hook that utilizes the useLocation hook from react-router-dom to get the current path of the browser's location. 
 */
const usePathname = () => {
  const pathname = useLocation().pathname;
  return pathname;
};

export default usePathname;
