import React, { FC } from "react";
import Avatar from "components/Avatar/Avatar";
// import Link from "components/Link"; // Comment out Link for now, use it again once author pages are implemented
import { PostDataType } from "data/types/post";

export interface CardAuthorProps
  extends Pick<PostDataType, "date" | "author"> {
  className?: string;
  readingTime?: PostDataType["readingTime"];
  hoverReadingTime?: boolean;
}

const CardAuthor: FC<CardAuthorProps> = ({
  className = "",
  author,
  readingTime,
  date,
  hoverReadingTime = true,
}) => {
  const { displayName, /* href = "/", */ avatar } = author; // Comment out href as it's not currently used
  // console.log("author in avatar card", avatar);
  return (
    // Replace Link with div and add a TODO comment
    <div className={`nc-CardAuthor relative inline-flex items-center ${className}`}>
      {/* TODO: Convert this div back to Link after author page implementation */}
      {/* <Link href={href} className="..."> */}
        <Avatar
          sizeClass="h-10 w-10 text-base"
          containerClassName="flex-shrink-0 mr-3"
          radius="rounded-full"
          imgUrl={avatar}
          userName={displayName}
        />
        <div>
          <h2 className={`text-sm text-neutral-700 dark:text-neutral-300 font-medium`}>
            {displayName}
          </h2>
          <span className={`flex items-center mt-1 text-xs text-neutral-500 dark:text-neutral-400`}>
            <span>{date}</span>
            {readingTime && (
              <>
                <span className={`hidden lg:inline mx-1 transition-opacity ${hoverReadingTime ? "opacity-0 group-hover:opacity-100" : ""}`}>
                  ·
                </span>
                <span className={`hidden lg:inline transition-opacity ${hoverReadingTime ? "opacity-0 group-hover:opacity-100" : ""}`}>
                  {readingTime} min read
                </span>
              </>
            )}
          </span>
        </div>
      {/* </Link> */}
    </div>
  );
};

export default CardAuthor;
