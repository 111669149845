import React, { useState } from 'react';
import { sha256 } from 'js-sha256';

const HashGenerator: React.FC = () => {
  const [inputText, setInputText] = useState('');

  const calculateHash = (text: string) => {
    return sha256(text);
  };

  return (
    <div className="flex">
      <div className="w-1/2 p-4">
        <h2 className="text-xl font-semibold mb-4">Input</h2>
        <textarea
          value={inputText}
          onChange={(e) => setInputText(e.target.value)}
          className="w-full h-64 border rounded px-2 py-1"
          placeholder="Enter text to hash..."
        />
      </div>
      <div className="w-1/2 p-4">
        <h2 className="text-xl font-semibold mb-4">SHA-256 Hash</h2>
        <div className="bg-gray-100 rounded px-2 py-1 h-64 overflow-auto">
          {calculateHash(inputText)}
        </div>
      </div>
    </div>
  );
};

export default HashGenerator;
