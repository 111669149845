import React from "react";
import Modal from "react-modal";
import styled from "styled-components";
import ArticleBody from "components/Article/ArticleBody";

const ModalOverlay = styled.div`
  background-color: rgba(
    0,
    0,
    0,
    0.2
  ); // Lighter semi-transparent black background
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto; // Ensure the modal is scrollable if content is too tall
`;

const ModalContent = styled.div`
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  border: 1px solid #ccc; // Light grey border
  width: 95vw; // 95% of the viewport width
  max-width: 1200px; // Maximum width
  height: 95vh; // 95% of the viewport height
  overflow: auto; // Enable scrolling if the content is taller than the modal
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); // Optional: Adds a subtle shadow
  margin-top: 100px; // Add some space at the top
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Divider = styled.hr`
  margin: 20px 0;
  border: 0;
  height: 1px;
  background: #eaecef;
  background-image: linear-gradient(to right, #eaecef, #eaecef, #eaecef);
`;

const Title = styled.h2`
  margin-top: 0;
`;

const CloseButton = styled.button`
  font-size: 16px;
  cursor: pointer;
  background-color: #d9534f; // Bootstrap danger color
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  margin-top: 20px; // Space from the content
  &:hover {
    background-color: #c9302c; // Slightly darker for hover effect
  }
`;

Modal.setAppElement("#root");

interface PreviewModalProps {
  isModalOpen: boolean;
  closeModal: () => void;
  markdownText: string;
}

const PreviewModal: React.FC<PreviewModalProps> = ({
  isModalOpen,
  closeModal,
  markdownText,
}) => {
  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={closeModal}
      style={{
        content: {
          padding: 0,
          border: "none",
          background: "none",
          overflow: "visible",
        },
        overlay: {
          backgroundColor: "transparent",
        },
      }}
      contentLabel="Preview Modal"
    >
      <ModalOverlay onClick={closeModal}>
        <ModalContent onClick={(e) => e.stopPropagation()}>
          <div
            id="single-entry-content"
            className="prose lg:prose-lg !max-w-screen-md mx-auto dark:prose-invert"
          >
            <ArticleBody postData={{ content: "### your article preview" }} />
            <Divider></Divider>
            <ArticleBody postData={{ content: markdownText }} />
          </div>

          <CloseButton onClick={closeModal}>Close</CloseButton>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
};

export default PreviewModal;
