import React, { FC } from "react";
import Badge from "components/Badge/Badge";
import { PostDataType, TaxonomyType } from "data/types/post";
import { getTaxonomiesByNames } from "api/tags/Category";

export interface CategoryBadgeListProps {
  className?: string;
  itemClass?: string;
  categories: TaxonomyType[];
}
const CategoryBadgeList: FC<CategoryBadgeListProps> = ({
  className = "flex flex-wrap space-x-2",
  itemClass,
  categories,
}) => {
  console.log("categories", categories);
  return (
    <div
      className={`CategoryBadgeList ${className}`}
      data-nc-id="CategoryBadgeList"
    >
      {/* Show all badges on medium screens and larger */}
      <div className="hidden md:block">
        {categories.map((item, index) => (
          <Badge
            className={itemClass}
            key={index}
            name={item.name}
            href={item.href}
            color={item.color as any}
          />
        ))}
      </div>

      {/* Show only the first badge on small screens */}
      <div className="md:hidden">
        {categories && categories.length > 0 && (
          <Badge
            className={itemClass}
            name={categories[0].name}
            href={categories[0].href}
            color={categories[0].color as any}
          />
        )}
      </div>
    </div>
  );
};

export default CategoryBadgeList;
