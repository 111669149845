import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { getJWTToken } from "utils/auth/jwt";

interface PrivateRouteProps {
  children: React.ReactNode;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({
  children,
}) => {
  const location = useLocation();
  const token = getJWTToken();

  return (token) ? (
    <>{children}</>
  ) : (
    <Navigate to="/login" replace state={{ from: location }} />
  );
};

export default PrivateRoute;
