import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import { Page } from "./types";
import PageHome from "app/homepage";
import Page404 from "app/notfound/page";
import Footer from "components/Footer/Footer";
import SiteHeader from "app/SiteHeader";
import ArticlePage from "app/article/page";
import ArticlePreviewPage from "app/articlepreview/page";
import Login from "components/Login/Login";
import PrivateRoute from "components/Login/PrivateRoute";
import ArticleList from "app/article/listpage";
import PageImplementing from "app/implementing/page";
import { ActivityLogData, sendActivityLog } from "api/activity/activity";
import DashboardNotice from "app/dashboard/noticepage/noticepage";
import { LanguageProvider } from "utils/language/languageContext";
import AdvertisePage from "app/dashboard/advertisepage/advertisepage";
import NewsPage from "app/dashboard/newspage/newspage";
import CryptoTrendPage from "app/dashboard/cryptotrendpage/cryptotrendpage";
import AirdropArticleList from "app/article/airdroppage";
import BlockchainSimulator from "app/dashboard/educationpage/educationpage";
import BlockchainSimulatorPage from "app/dashboard/educationpage/blockchainsimulatorpage/blockchainsimulatorpage";
import LiveHashGeneratorPage from "app/dashboard/educationpage/livehashgeneratorpage/livehashgeneratorpage";
import DevelopArticleList from "app/article/developpage";
import CryptocurrencyArticleList from "app/article/cryptocurrencypage";

export const pages: Page[] = [
  { path: "/", component: PageHome },
  { path: "/blog", component: ArticleList },
  { path: "/blog/cryptocurrency", component: CryptocurrencyArticleList },
  { path: "/blog/develop", component: DevelopArticleList },
  { path: "/airdrop", component: AirdropArticleList },
  { path: "/blog/:postId", component: ArticlePage },
  { path: "/implementing", component: PageImplementing },
  { path: "/dashboard/notice", component: DashboardNotice },
  { path: "/explore/platform-pick", component: AdvertisePage },
  { path: "/explore/news", component: NewsPage },
  { path: "/explore/crypto-trend", component: CryptoTrendPage },
  { path: "/explore/education", component: BlockchainSimulatorPage},
  { path: "/explore/education/blockchain-simulator", component: BlockchainSimulatorPage },
  { path: "/explore/education/hash-generator", component: LiveHashGeneratorPage },
];

const RouteWatcher = () => {
  const location = useLocation();
  useEffect(() => {
    // Define the activity data
    const activityData: ActivityLogData = {
      path: location.pathname, // current path
      description: "", // describe the activity
      timestamp: Date.now(), // current time in ISO format
    };

    // Send the activity log
    sendActivityLog(activityData).catch(console.error); // Log errors if any
  }, [location]); // Re-run the effect when the location changes

  return null; // This component doesn't render anything
};

const MyRoutes: React.FC = () => {
  // TODO: you should handle logout case as well
  return (
    <BrowserRouter>
      <SiteHeader />
      <RouteWatcher />
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route
          path="/article-preview"
          element={
            <PrivateRoute>
              <ArticlePreviewPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/article-preview/:postId"
          element={
            <PrivateRoute>
              <ArticlePreviewPage />
            </PrivateRoute>
          }
        />
        {pages.map((page, index) => (
          <Route key={index} path={page.path} element={<page.component />} />
        ))}
        <Route path="*" element={<Page404 />} />{" "}
        {/* This is the catch-all route */}
        {/* Add other routes as needed */}
      </Routes>
    </BrowserRouter>
  );
};

export default MyRoutes;
