import React from "react";
import ForeignLogoSvg from "./ForeignLogoSvg";
import Link from "components/Link";

export interface LogoProps {
  img?: string;
  href: string;
}

const ForeignLogo: React.FC<LogoProps> = ({
  img, // No default value needed here since it's optional
  href,
}) => {
  // console.log("her",href)
  return (
    <Link
      href={href}
      target="_blank" 
      className="ttnc-logo inline-block text-primary-6000 flex-shrink-0"
    >
      <ForeignLogoSvg img={img} />
    </Link>
  );
};

export default ForeignLogo;
