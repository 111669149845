import React from "react";
import Modal from "react-modal";
import styled from "styled-components";
import ArticleBody from "components/Article/ArticleBody";
import ArticleHeader from "components/Article/ArticleHeader";
import NoticeTitle from "./NoticeTitle";
import CardAuthor from "components/Card/Author/CardAuthor";
import { getAuthorData } from "api/authors/Authors";
import { PostAuthorType } from "data/types/author";

const ModalOverlay = styled.div`
  background-color: rgba(
    0,
    0,
    0,
    0.2
  ); // Lighter semi-transparent black background
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto; // Ensure the modal is scrollable if content is too tall
  height: 100%; /* Set to 100% to match the height of ModalContent */
`;

const ModalContent = styled.div`
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  border: 1px solid #ccc;
  width: 80vw;
  height: 65vh;
  max-width: 1200px;
  overflow: auto;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 768px) {
    /* Adjust font size for screens with a maximum width of 768px (typical mobile devices) */
    font-size: 14px; /* You can adjust the font size as needed */
  }
`;

const Divider = styled.hr`
  margin: 20px 0;
  border: 0;
  height: 1px;
  background: #eaecef;
  background-image: linear-gradient(to right, #eaecef, #eaecef, #eaecef);
`;

const Title = styled.h2`
  margin-top: 0;
`;

const CloseButton = styled.button`
  font-size: 16px;
  cursor: pointer;
  background-color: #d9534f; // Bootstrap danger color
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  margin-top: 20px; // Space from the content
  &:hover {
    background-color: #c9302c; // Slightly darker for hover effect
  }
`;

const GoBackButton = styled.button`
  font-size: 16px;
  cursor: pointer;
  background-color: transparent;
  color: #333; /* Change color as needed */
  border: none;
  padding: 10px;
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 2;
`;

Modal.setAppElement("#root");

export interface NoticeProps {
  id: string;
  title: string;
  content: string;
  date: string;
  author: PostAuthorType;
}

interface NoticeModalProps {
  isModalOpen: boolean;
  closeModal: () => void;
  markdownTitle: string;
  markdownText: string;
  date: string;
}

const NoticeModal: React.FC<NoticeModalProps> = ({
  isModalOpen,
  closeModal,
  markdownTitle,
  markdownText,
  date,
}) => {
  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={closeModal}
      style={{
        content: {
          padding: 0,
          border: "none",
          background: "none",
          overflow: "visible",
          height: "auto",
        },
        overlay: {
          backgroundColor: "transparent",
        },
      }}
      contentLabel="Preview Modal"
    >
      <ModalOverlay onClick={closeModal}>
        <ModalContent onClick={(e) => e.stopPropagation()}>
          <GoBackButton
            className={`md:hidden absolute top-3 left-3 ${
              isModalOpen ? "block" : "hidden"
            }`}
            onClick={closeModal}
          >
            Go Back
          </GoBackButton>
          <div className="nc-ArticleContent space-y-10">
          <div id="single-entry-content" className="container mt-10">
            <CardAuthor
              className="relative"
              author={getAuthorData()[0]}
              date={date}
            />
            <div
              id="single-entry-content"
              className="container mt-10 shadow dark:border dark:border-neutral-800 sm:rounded-lg pb-10"
            >
              <NoticeTitle title={markdownTitle} />

              <div className="flex flex-col sm:flex-row justify-between sm:items-end space-y-5 sm:space-y-0 sm:space-x-5"></div>
              <Divider></Divider>
              
                {/* ENTRY CONTENT */}
                <div
                  id="single-entry-content"
                  className="prose lg:prose-lg !max-w-screen-md mx-auto dark:prose-invert"
                >
                  <ArticleBody postData={{ content: markdownText }} />
                </div>
              </div>
            </div>
          </div>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
};

export default NoticeModal;
