import React, { FC } from "react";

export interface TabProps {
  containerClassName?: string;
  className?: string;
  children: React.ReactNode;
}

const Tab: FC<TabProps> = ({
  containerClassName = "",
  className = "justify-end",
  children,
}) => {
  return (
    <nav className={`nc-Nav ${containerClassName}`} data-nc-id="Nav">
      <ul className={`flex  ${className}`}>{children}</ul>
    </nav>
  );
};

export default Tab;
