import React, { FC, useState } from "react";
import CategoryBadgeList from "components/CategoryBadgeList/CategoryBadgeList";
import Link from "components/Link";
import AdvertiseImageCard from "./AdvertiseImage";
import AdvertiseDescription from "./AdvertiseDescription";
import { PostDataType, TaxonomyType } from "data/types/post";
import Image from "components/Image/Image";
import PostTypeFeaturedIcon from "components/PostTypeFeaturedIcon/PostTypeFeaturedIcon";

export interface AdvertiseCardProps {
  className?: string;
  ratio?: string;
  post: PostDataType;
}

const AdvertiseCard: FC<AdvertiseCardProps> = ({
  className = "h-full",
  ratio = "aspect-w-3 aspect-h-3 sm:aspect-h-4",
  post,
}) => {
  const { href, categories, title, author, date, featuredImage } = post;
  const [isHover, setIsHover] = useState(false);
  // console.log("post in advertise card", post);

  return (
    <div
      className={`nc-Card7 relative flex flex-col group rounded-3xl overflow-hidden z-0 ${className}`}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <div
        className={`absolute bottom-3 inset-x-3 p-4 bg-white dark:bg-neutral-900 flex flex-col flex-grow rounded-3xl group-hover:shadow-2xl transition-shadow ${ratio}`}
      ></div>
      <Link href={href} className={`flex items-start relative w-full ${ratio}`}>
        <div
          className="w-full h-full rounded-3xl overflow-hidden"
          style={{ backgroundColor: "#000" }} // Set the background to black
        >
          <Image
            fill
            alt=""
            className="object-contain w-full h-full rounded-3xl" // object-cover -> object-contain if image size fits 480, 500px
            src={featuredImage}
            sizes="(max-width: 600px) 480px,800px"
          />
        </div>
        {/* <div
          className="absolute top-3 left-3 p-2 rounded-lg group-hover:hidden transition-shadow text-base font-semibold text-center"
          style={{
            backgroundColor: "rgba(255, 255, 255, 0.7)", // Light mode with 70% opacity
            backdropFilter: "blur(10px)", // Optional: Apply a blur to the background
            color: "#333", // Adjust text color as needed
            maxHeight: "8%", // You can keep or adjust this as needed
            maxWidth: "auto",
          }}
        >
          <Link href={href} title={title} className="line-clamp-2 text-sm">
            {"BQ가 주목하는 프로젝트"}
          </Link>
        </div> */}

        <PostTypeFeaturedIcon
          className="absolute top-3 left-3 group-hover:hidden"
          postType={"advertiseSite"}
          wrapSize="w-7 h-7"
          iconSize="w-4 h-4"
        />
        <span className="absolute inset-0 bg-black bg-opacity-10 opacity-0 group-hover:opacity-100 transition-opacity"></span>
      </Link>
      <div className="absolute bottom-3 inset-x-3 p-4 bg-white dark:bg-neutral-900 flex flex-col flex-grow rounded-3xl group-hover:shadow-2xl transition-shadow  group-hover:opacity-100">
        <Link href={href} className="absolute inset-0"></Link>
        <div className="space-y-2.5 mb-3">
          <CategoryBadgeList categories={categories as TaxonomyType[]} />
          <h2 className="block text-base font-semibold text-neutral-900 dark:text-neutral-100 ">
            <Link href={href} title={title} className="line-clamp-2">
              {title}
            </Link>
          </h2>
          <p className="text-sm text-neutral-600 font-semibold dark:text-neutral-300 line-clamp-3">
            {post.content}
          </p>
        </div>
      </div>
    </div>
  );
};

export default AdvertiseCard;
