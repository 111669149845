import React, { FC, useEffect, useState } from "react";
import Heading from "components/Heading/Heading";
import ButtonPrimary from "components/Button/ButtonPrimary";
import ArticleCard from "./ArticleCard";
import { PostDataType } from "data/types/post";
import { DEMO_POSTS } from "api/posts/posts";
import Pagination from "./Pagination";
import axios from "axios";
import SectionAds from "components/Advertisement/SectionAds";
import { PostAuthorType } from "data/types/author";
import { getAuthorData } from "api/authors/Authors";
import { formatDate } from "utils/date/dateutil";
import { getTaxonomiesByNames } from "api/tags/Category";

// THIS IS DEMO FOR MAIN DEMO
// OTHER DEMO WILL PASS PROPS
const authors: PostAuthorType[] = getAuthorData();
//
export interface ArticleListPageProps {
  gridClass?: string;
  className?: string;
  heading?: string;
  postCardName?: "articleCard";
  postType?: "standard" | "airdrop" | "develop" | "cryptocurrency" | "standard,cryptocurrency,develop";
}

const API_BASE_URL = process.env.REACT_APP_API_HOST;
// const API_BASE_URL = "http://localhost:8080";
const ArticleListPage: FC<ArticleListPageProps> = ({
  postCardName = "articleCard",
  heading = "BQ's 인사이트",
  gridClass = "",
  className = "",
  postType = "standard",
}) => {
  const [posts, setPosts] = useState<PostDataType[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const pageSize = 10; // Set your page size here

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/posts`, {
          params: {
            page: currentPage - 1,
            size: pageSize,
            sortBy: "date",
            postType: postType,
          },
        });
        // Adjust below according to your API response structure
        const result = response.data.content.map((post: any) => {
          return {
            ...post,
            date: formatDate(post.date),
            href: post.href || '',
            categories: getTaxonomiesByNames(post.categories),
            author: authors.filter((author) => author.displayName === post.author)[0] || authors[0],
          }
        }
        )
        setPosts(result);
        setTotalPages(response.data.totalPages);
      } catch (error) {
        console.error("Error fetching posts:", error);
      }
    };

    fetchPosts();
  }, [currentPage]);

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const renderCard = (post: PostDataType, index: number) => {
    return <ArticleCard key={index} className="py-3" post={post} />;
  };

  // console.log("posts", posts);

  return (
    
    <div className={`nc-SectionLatestPosts relative  ${className}`}> 
      <div className="flex flex-col lg:flex-row">
        <div className="w-full ">
          <Heading>{heading}</Heading>
          <div className={`grid gap-6 md:gap-8 ${gridClass}`}>
            {posts.map(renderCard)}
          </div>
          <div className="flex flex-col mt-12 md:mt-20 space-y-5 sm:space-y-0 sm:space-x-3 sm:flex-row sm:justify-between sm:items-center">
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
            />
          </div>
        </div>
        <div className="w-full space-y-7 mt-24 lg:mt-0 lg:w-2/5 lg:pl-10 xl:pl-0 xl:w-1/3 ">
          {/* <WidgetTags />
          <WidgetCategories />
          <WidgetAuthors />
          <WidgetPosts /> */}
        </div>
      </div>
      <div className="relative py-16">
        <SectionAds></SectionAds>
      </div>
    </div>
  );
};

export default ArticleListPage;
