import { getAuthorData } from "api/authors/Authors";
import axios from "axios";
import Pagination from "components/ArticleListPage/Pagination";
import Avatar from "components/Avatar/Avatar";
import DashboardLayout from "components/Dashboard/DashboardLayout";
import NoticeModal, { NoticeProps } from "components/Dashboard/NoticeModal";
import React, { useEffect, useState } from "react";
import { getJWTToken } from "utils/auth/jwt";
import { formatDate2 } from "utils/date/dateutil";
import { useLanguage } from "utils/language/languageContext";

const API_BASE_URL = process.env.REACT_APP_API_HOST;
const authors = getAuthorData();
const DashboardNotice = () => {
  const token = getJWTToken();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedNotice, setSelectedNotice] = useState({
    title: "",
    content: "",
    date: "",
  });
  const [noticePosts, setNoticePosts] = useState<NoticeProps[]>([]);
  const [noticeCurrentPage, setNoticeCurrentPage] = useState(1);
  const [noticeTotalPages, setNoticeTotalPages] = useState(0);
  const noticePageSize = 10; // Set your page size here
  const { selectedLanguage } = useLanguage();
  useEffect(() => {
    const fetchNoticePosts = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/posts`, {
          params: {
            page: noticeCurrentPage - 1,
            size: noticePageSize,
            sortBy: "date",
            postType: "notice",
          },
        });

        const result = response.data.content.map((post: any) => {
          return {
            id: post.id,
            title: post.title,
            content: post.content || "", // Or any other text you want to show
            date: formatDate2(post.date) || "",
            author: authors.filter(
              (author) => author.displayName === post.author
            )[0],
          };
        });

        setNoticePosts(result);
        setNoticeTotalPages(response.data.totalPages);

        console.log('Selected language:', selectedLanguage);
      } catch (error) {
        console.error("Error fetching notice posts:", error);
      }
    };

    fetchNoticePosts();
  }, [noticeCurrentPage, selectedLanguage]);
  useEffect(() => {
    console.log('Selected language:', selectedLanguage);
  }, [selectedLanguage]); // Listen for changes in selectedLanguage
  
  const handleNoticeClick = (notice: NoticeProps) => {
    setSelectedNotice({
      title: notice.title,
      content: notice.content, // Or any other text you want to show
      date: notice.date,
    });
    if (token) {
      console.log({
        id: notice.id,
        title: notice.title,
      });
    }
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleNoticePageChange = (pageNumber: number) => {
    setNoticeCurrentPage(pageNumber);
  };

  return (
    <div className={`nc-LayoutPage relative`}>
      <div
        className={`absolute h-[400px] top-0 left-0 right-0 w-full bg-primary-100 dark:bg-neutral-800 bg-opacity-25 dark:bg-opacity-40`}
      />
      <div className="container relative pt-6 sm:pt-10 pb-16 lg:pt-20 lg:pb-28">
        {/* CONTENT */}
        <div className="p-5 mx-auto bg-white rounded-xl sm:rounded-3xl lg:rounded-[40px] shadow-lg sm:p-10 lg:p-16 dark:bg-neutral-900">
          <DashboardLayout>
            <div className="flex flex-col space-y-8">
              <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full px-1 sm:px-6 lg:px-8">
                  <div className="shadow dark:border dark:border-neutral-800 overflow-hidden sm:rounded-lg">
                    <table className="min-w-full divide-y divide-neutral-200 dark:divide-neutral-800">
                      <thead className="bg-neutral-50 dark:bg-neutral-800">
                        <tr className="text-center text-xs font-medium text-neutral-500 dark:text-neutral-300 uppercase tracking-wider">
                          <th
                            scope="col"
                            className="px-6 py-3"
                            style={{ width: "60%" }}
                          >
                            제목
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3"
                            style={{ width: "30%" }}
                          >
                            날짜
                          </th>
                        </tr>
                      </thead>
                      <tbody className="text-center bg-white dark:bg-neutral-900 divide-y divide-neutral-200 dark:divide-neutral-800">
                        {noticePosts.map((item, index) => (
                          <tr
                            key={index}
                            onClick={() => handleNoticeClick(item)}
                            style={{ cursor: "pointer" }}
                          >
                            <td
                              className="px-6 py-4 whitespace-nowrap text-sm text-neutral-500 dark:text-neutral-400"
                              style={{ width: "70%" }}
                            >
                              <div className="flex items-center">
                                <Avatar
                                  imgUrl={item.author.avatar}
                                  userName={item.author.displayName}
                                  sizeClass="h-6 w-6 text-lg sm:text-xl sm:h-8 sm:w-8"
                                />
                                <span className="ml-4 text-lg sm:text-xl">
                                  {" "}
                                  {/* Increased margin from ml-2 to ml-4 */}
                                  {item.title}
                                </span>
                              </div>
                            </td>
                            <td
                              className="px-6 py-4 text-center whitespace-nowrap"
                              style={{ width: "30%" }}
                            >
                              <span> {item.date}</span>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <Pagination
                currentPage={noticeCurrentPage}
                totalPages={noticeTotalPages}
                onPageChange={handleNoticePageChange}
              />
            </div>
          </DashboardLayout>
        </div>
      </div>
      <NoticeModal
        isModalOpen={isModalOpen}
        closeModal={closeModal}
        markdownTitle={selectedNotice.title}
        markdownText={selectedNotice.content}
        date={selectedNotice.date}
      />
    </div>
  );
};

export default DashboardNotice;
