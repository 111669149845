// DatePickerComponent.tsx
import React, { useState } from 'react';
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

interface DatePickerProps {
  selectedDate: Date | null;
  onChange: (date: Date) => void;
}

const DatePickerComponent: React.FC<DatePickerProps> = ({ selectedDate, onChange }) => {
  return (
    <ReactDatePicker
      selected={selectedDate}
      onChange={onChange}
      showTimeSelect
      timeFormat="HH:mm"
      timeIntervals={15}
      timeCaption="time"
      dateFormat="yyyy-MM-dd HH:mm:ss"
    />
  );
};

export default DatePickerComponent;
