import { DEMO_POSTS } from "api/posts/posts";
import Avatar from "components/Avatar/Avatar";
import WidgetHeading1 from "components/News/WidgetHeading";
import { PostDataType } from "data/types/post";

import React, { FC } from "react";
import Link, { NewTabLink } from "components/Link";

const newsPostsDemo: PostDataType[] = DEMO_POSTS.filter(
  (_, i) => i > 2 && i < 7
);

export interface NewsPostsProps {
  title?: string;
  className?: string;
  posts?: PostDataType[];
  href?: string;
  avatar?: React.ReactNode;
}

const NewsPosts: FC<NewsPostsProps> = ({
  title = "News",
  className = "bg-neutral-100 dark:bg-neutral-800",
  posts = newsPostsDemo,
  avatar = null,
  href = "/",
}) => {
  return (
    <div className={`nc-WidgetPosts rounded-3xl overflow-hidden ${className}`}>
      <WidgetHeading1
        title={title}
        viewAll={{ label: "View all", href: href }}
        hiddenAvatar={false}
        avatar={avatar}
      />
      <div className="justify-between p-4 xl:p-5 flex flex-col divide-y divide-neutral-200 dark:divide-neutral-700">
        {posts.map((post) => (
          <div
            key={post.id}
            className="flex flex-col justify-between items-start py-3" // Changed to vertical layout
          >
            <NewTabLink target="_blank" rel="noopener noreferrer" href={post.href} >
              <div className="font-semibold line-clamp-2">{post.title}</div> {/* Applied line clamp */}
            </NewTabLink>

            <div className="text-sm text-gray-500">{post.date}</div>
          </div>
        ))}
      </div>
    </div> 
  );
};

export default NewsPosts;
