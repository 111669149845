import React, { FC } from "react";
import NavigationItem from "./NavigationItem";
import { NAVIGATION_ITEM } from "data/navigation/navigation";

export interface NavigationProps {
  className?: string;
}

const Navigation: FC<NavigationProps> = ({ className = "flex" }) => {
  return (
    <ul className={`nc-Navigation items-center ${className}`}>
      {NAVIGATION_ITEM.map((item) => (
        <NavigationItem key={item.id} menuItem={item} />
      ))}
    
    </ul>
  );
};

export default Navigation;
