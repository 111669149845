import React, { ButtonHTMLAttributes, FC } from "react";
import { Route } from "routers/types";
import Link from "components/Link";
import Loading from "./Loading";

export interface ButtonProps {
  className?: string;
  sizeClass?: string;
  fontSize?: string;
  pattern?: "primary" | "secondary" | "third" | "white" | "default";
  //
  loading?: boolean;
  disabled?: boolean;
  //type can have one of three values
  //    submit: The button submits the form data to the server. This is the default if the attribute is not specified, or if the attribute is dynamically changed to an empty or invalid value.
  //    reset: The button resets all the form fields to their initial values.
  //    button: The button has no default behavior and does nothing when pressed by itself. It can be used in conjunction with client-side scripting (like JavaScript) to create more complex behaviors.
  type?: ButtonHTMLAttributes<HTMLButtonElement>["type"];
  href?: Route;
  targetBlank?: boolean;
  onClick?: () => void;
  children?: React.ReactNode;
}

const Button: FC<ButtonProps> = ({
  pattern = "default",
  className = "",
  sizeClass = "py-3 px-4 sm:py-3.5 sm:px-6",
  fontSize = "text-sm sm:text-base font-medium",
  disabled = false,
  href,
  children,
  type = "button", // default is submit
  loading,
  targetBlank,
  onClick = () => {},
}) => {
  let colors =
    "bg-neutral-900 hover:bg-neutral-800 text-white dark:bg-neutral-100 dark:hover:bg-neutral-50 dark:text-black";
  switch (pattern) {
    case "primary":
      colors = "bg-primary-700 hover:bg-primary-6000 text-primary-50";
      break;
    case "secondary":
      colors = "bg-secondary-500 hover:bg-secondary-6000 text-secondary-50";
      break;
    case "white":
      colors =
        "bg-white dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200";
      break;
    case "third":
      colors =
        "bg-white dark:bg-neutral-900 ring-1 ring-neutral-300 hover:ring-neutral-400 dark:ring-neutral-700 dark:hover:ring-neutral-500";
      break;

    default:
      break;
  }

  // it determines shape of button
  let CLASSES = `nc-Button flex-shrink-0 relative h-auto inline-flex items-center justify-center rounded-full transition-colors border-transparent ${colors} ${fontSize} ${sizeClass} ${className} `;

  if (!!href) {
    return (
      <Link href={href} 
      className={`${CLASSES} `} 
      target={targetBlank ? "_blank" : "_self"}
      onClick={onClick}>
        {loading && <Loading />}
        {children || `This is Link`}
      </Link>
    );
  }

  return (
    <button
      disabled={disabled || loading} // disabled when loading is true
      className={`${CLASSES}`}
      onClick={onClick}
      type={type}
    >
      {loading && <Loading />}
      {children || `Button default`}
    </button>
  );
};

export default Button;
